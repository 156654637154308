<template>
    <div class="step-component">
        <template v-if="props.type == 1">
            <div class="step-one-active step-item">
                <span>1</span>
                <span>项目基本信息</span>
            </div>
            <div
                class="step-item"
                :class="stepCount >= 1 ? 'step-two-active' : 'step-two'"
            >
                <span>2</span>
                <span>项目建设信息</span>
            </div>
            <div class="step-item" :class="stepCount >= 2 ? 'step-three-active' : 'step-three'">
                <span>3</span>
                <span>项目投资设置</span>
            </div>
        </template>
        <template v-else>
            <div class="step-one-active step-item">
                <span>1</span>
                <span>项目基本信息</span>
            </div>
            <div class="step-item" :class="stepCount >= 1 ? 'step-three-active' : 'step-three'">
                <span>2</span>
                <span>项目投资设置</span>
            </div>
        </template>
        
    </div>
    <slot name="header" :stepCount="stepCount"></slot>
</template>
<style scoped lang="scss">
    @import './Steps.scss';
</style>
<script setup>
    import eventBus from '@/utils/eventBus';
    import { reactive, ref, onMounted, onUnmounted } from 'vue';
    import usePublicDataStore from '@/pinia/modules/public';
    const publicDataStore = usePublicDataStore()
    // import ObsClient from '@/components/obsClient/obsClient.vue'; 
    let stepCount = ref(0);
    let packageType = ref(0); // 0代表风电、1代表光伏
    const props = defineProps({
        // 1 风电可研 2 项目建议书
        type: {
            type: Number,
            default: 1
        },
    });
    onMounted(() => {
        packageType.value = publicDataStore.headActiveKey == 'light' ? 1 : 0;
        eventBus.on('validateStep', onValidateStep);
        // setTimeout(() => {
        //     eventBus.emit('validateStep', 3);
        // }, 3000);
    });
    onUnmounted(() => {
        eventBus.off('validateStep');
    });
    // 手动触发验证
    const validate = (type) => {
        eventBus.emit('handleValidateEvent', type); // 2代表保存成草稿 1代表进行保存并进行下一步
    };
    // 变更步骤
    const onValidateStep = (value) => {
        stepCount.value = value - 1;
    };
</script>
