<template>
    <div class="three-component" v-loading="[formLoading, true]">
        <div class="illustrate">
            <iconpark-icon class="illustrate-icon" :size="16" name="jinggao"></iconpark-icon>
            <span
                >说明：系统提供以下费用项的默认值，您可根据公司项目进行相关费用的单价调整，也可不进行调整</span
            >
        </div>
        <EditTable ref="editTableRef" :columns="columns" :dataSource="filterDataSource(dataSource)" @updateRangeYear="updateRangeYearEvent"></EditTable>
        <div class="handle-submit-group">
            <a-button
                v-if="route.query.type !== 'edit'"
                v-softDog="{
                    callback: onBeforeSubmitForm,
                    params: 1,
                    packageType: PACKAGE_TYPE.default,
                }"
                >保存草稿</a-button
            >
            <a-button
                class="step-next"
                v-softDog="{
                    callback: onBeforeSubmitForm,
                    params: 2,
                    packageType: PACKAGE_TYPE.default,
                }"
                >一键生成报告</a-button
            >
        </div>
        <projectPackage
            :TipsDialogVisible="TipsDialogVisible"
            :messageType="messageType"
            @changeShowKey="TipsDialogVisible = false"
        ></projectPackage>
    </div>
</template>
<script setup>
    import { ref, reactive, onMounted, onUnmounted, h, nextTick, computed } from 'vue';
    import { useRoute, useRouter } from 'vue-router';
    import EditTable from '@/components/editTable/EditTable.vue';
    import {
        getThreeStepParam, // 获取第三步骤默认值
    } from '@/api/modules/light/threeStep';
    import { saveFormData, updateFormData, selectProjectInfoById } from '@/api/modules/light/firstStep';
    import { checkAdjustProjectIsDeductTimes } from '@/api/modules/project';
    import { message } from 'ant-design-vue';
    import eventBus from '@/utils/eventBus';
    import projectPackage from '@/components/projectPackage/projectPackage.vue';
    import { checkPackageEffective } from '@/api/modules/reportIndex.js';
    import { PACKAGE_TYPE } from '@/assets/js/static.js';
    import { Modal } from 'ant-design-vue';
    const editTableRef = ref();
    // 提交loading
    const formLoading = ref(false);
    const route = useRoute();
    const router = useRouter();
    const TipsDialogVisible = ref(false);
    const messageType = ref(1);
    // 光伏组件质保年限
    const rangYear = ref(3);
    const warrantyOutFixX1Y1 = computed(() => {
        return `修理费（质保期外${rangYear.value + 1}-${
                Math.ceil(25 / rangYear.value) + rangYear.value
            }年）`;
    });
    const warrantyOutFixX2X25 = computed(() => {
        return `修理费（质保期外${Math.ceil(25 / rangYear.value) + rangYear.value + 1}-25年）`;
    })
    // 数据源常量
    const dataSource = ref([
        {
            name: '光伏组件单价',
            dataIndex: 'pvMondule',
            unit: '元/kW',
            default: 700,
            price: null,
            min: 100,
            max: 10000,
            type: '设备单价'
        },
        {
            name: '逆变器单价',
            dataIndex: 'tendInverter',
            unit: '元/kW',
            default: 8,
            price: null,
            min: 1,
            max: 100,
            type: '设备单价',
        },
        {
            name: '主变压器单价',
            dataIndex: 'mainTransformer',
            unit: '元/MVA',
            default: 50000,
            price: null,
            min: 10000,
            max: 100000,
            type: '设备单价',
        },
        {
            name: '箱变单价',
            dataIndex: 'boxTransformer',
            unit: '元/kVA',
            default: 95,
            price: null,
            min: 10,
            max: 1000,
            type: '设备单价',
        },
        {
            name: '光伏支架单价',
            dataIndex: 'pvBracket',
            unit: '元/t',
            default: 7500,
            price: null,
            min: 1000,
            max: 100000,
            type: '设备单价',
        },
        {
            name: `项目资本金比例`,
            dataIndex: 'fundingRatio',
            unit: '%',
            default: 20,
            price: null,
            min: 20,
            max: 100,
            type: '融资金额',
        },
        {
            name: `长期贷款利率`,
            dataIndex: 'longLoanRate',
            unit: '%',
            default: 3.85,
            price: null,
            min: 0,
            max: 20,
            type: '融资金额',
        },
        {
            name: `短期贷款利率`,
            dataIndex: 'shortLoanRate',
            unit: '%',
            default: 3.35,
            price: null,
            min: 0,
            max: 20,
            type: '融资金额',
        },
        {
            name: `流动资金贷款利率`,
            dataIndex: 'workingCapitalLoanRate',
            unit: '%',
            default: 3.35,
            price: null,
            min: 0,
            max: 20,
            type: '融资金额',
        },
        {
            name: '定员人数',
            dataIndex: 'fixNumPerson',
            unit: '人',
            default: 8,
            price: null,
            min: 1,
            max: 100,
            type: '运维费用',
        },
        {
            name: '人工工资（不含福利）',
            dataIndex: 'wages',
            unit: '人/年',
            default: 80000,
            price: null,
            min: 50000,
            max: 1000000,
            type: '运维费用',
        },
        {
            name: '保险费率',
            dataIndex: 'safeRate',
            unit: '%',
            default: 0.25,
            price: null,
            min: 0,
            max: 1,
            type: '运维费用',
        },
        {
            name: '光伏组件质保期',
            dataIndex: 'deviceWarranty',
            unit: '年',
            default: 3,
            price: null,
            min: 1,
            max: 50,
            type: '运维费用',
        },
        {
            name: '设备折旧年限',
            dataIndex: 'equipmentDepreciationPeriod',
            unit: '年',
            default: 20,
            price: null,
            min: 5,
            max: 20,
            type: '运维费用',
        },
        {
            name: '设备折旧残值',
            dataIndex: 'equipmentDepreciationResidual',
            unit: '%',
            default: 5,
            price: null,
            min: 0,
            max: 5,
            type: '运维费用',
        },
        {
            name: '修理费（质保期内）',
            dataIndex: 'warrantyInFix',
            unit: '固定资产原值%',
            default: 16,
            price: null,
            min: 0,
            max: 1000,
            type: '运维费用',
        },
        {
            name: warrantyOutFixX1Y1.value,
            dataIndex: 'warrantyOutFixX1Y1',
            unit: '固定资产原值%',
            default: 0.3,
            price: null,
            min: 0.01,
            max: 1,
            type: '运维费用',
        },
        {
            name: warrantyOutFixX2X25.value,
            dataIndex: 'warrantyOutFixX2X25',
            unit: '固定资产原值%',
            default: 0.5,
            price: null,
            min: 0.01,
            max: 1,
            type: '运维费用',
        },
        {
            name: '材料费',
            dataIndex: 'warrantyMaterial',
            unit: '元/kW',
            default: 8,
            price: null,
            min: 0,
            max: 100,
            type: '运维费用',
        },
        {
            name: '其他费',
            dataIndex: 'otherFee',
            unit: '元/kW',
            default: 30,
            price: null,
            min: 0,
            max: 100,
            type: '运维费用',
        },
        {
            name: '长期租地费用',
            dataIndex: 'longTermLandRentalCosts',
            unit: '元/亩/年',
            default: 600,
            price: null,
            min: 0,
            max: 10000,
            type: '用地费用',
        },
        {
            name: '永久征地费',
            dataIndex: 'permanentLandTaxCollection',
            unit: '元/亩',
            default: 150000,
            price: null,
            min: 100000,
            max: 1000000,
            type: '用地费用',
        },
        {
            name: '耕地占用税',
            dataIndex: 'farmlandOccupationTax',
            unit: '元/亩',
            default: 330,
            price: null,
            min: 100,
            max: 1000,
            type: '用地费用',
        },
        {
            name: '耕地占用面积',
            dataIndex: 'farmlandOccupationTax',
            unit: '亩',
            default: 0,
            price: null,
            min: 0,
            max: 1000000,
            type: '用地费用',
        },
        {
            name: '青苗补偿费',
            dataIndex: 'compensationFeeYoungCrops',
            unit: '元/亩',
            default: 30000,
            price: null,
            min: 10000,
            max: 100000,
            type: '用地费用',
        },
        {
            name: '青苗补偿面积',
            dataIndex: 'compensationAreaYoungCrops',
            unit: '亩',
            default: 0,
            price: null,
            min: 0,
            max: 1000000,
            type: '用地费用',
        },
        {
            name: '林地补偿费',
            dataIndex: 'forestCompensationFee',
            unit: '元/亩',
            default: 12000,
            price: null,
            min: 1000,
            max: 100000,
            type: '用地费用',
        },
        {
            name: '林地补偿面积',
            dataIndex: 'compensationAreaForestLand',
            unit: '亩',
            default: 0,
            price: null,
            min: 0,
            max: 1000000,
            type: '用地费用',
        },
        {
            name: '基本预备费费率',
            dataIndex: 'basicContingencyRate',
            unit: '%',
            default: 0,
            price: null,
            min: 0,
            max: 100,
            type: '预备费用',
        },
    ]);
    const updateRangeYearEvent = (value) => {
        console.log(value);
        nextTick(() => {
            const originDataSource = JSON.parse(JSON.stringify(editTableRef.value.dataList));
            if (value) {
                rangYear.value = value;
            } else {
                rangYear.value = 3;
            }
            const index1 = dataSource.value.findIndex(item => item.dataIndex == 'warrantyOutFixX1Y1');
            if (index1 > -1) {
                originDataSource[index1].name = warrantyOutFixX1Y1.value;
            }
            const index2 = dataSource.value.findIndex(item => item.dataIndex == 'warrantyOutFixX2X25');
            if (index2 > -1) {
                originDataSource[index2].name = warrantyOutFixX2X25.value;
            }
            dataSource.value =  JSON.parse(JSON.stringify(originDataSource));
            console.log(originDataSource, rangYear.value, warrantyOutFixX1Y1.value, warrantyOutFixX2X25.value, dataSource.value);
        })
    }
    // 详情唯一标识id
    const projectDetailID = ref('');
    // 表头常量
    const columns = ref([
        {
            title: '类型',
            dataIndex: 'type',
            width: '17%',
            align: 'center',
            customCell: (_, index) => {
                console.log(_);
                if (index == 0) {
                    return {
                        rowSpan: 5,
                    };
                } else if (index == 5) {
                    return {
                        rowSpan: 4,
                    };
                } else if (index == 9) {
                    return {
                        rowSpan: 11,
                    };
                } else if (index == 20) {
                    return {
                        rowSpan: 8,
                    };
                } else if (index == 28) {
                    return {
                        rowSpan: 1,
                    };
                } else {
                    return {
                        rowSpan: 0,
                    };
                }
                // if (index == 2) {
                //     return {
                //         rowSpan: 0
                //     }
                // }
            },
        },
        {
            title: '费用项',
            dataIndex: 'name',
            width: '28%',
            align: 'center',
        },
        {
            title: '单位',
            dataIndex: 'unit',
            width: '15%',
            align: 'center',
        },
        {
            title: '默认值/单价(元)',
            dataIndex: 'default',
            width: '20%',
            align: 'center',
        },
        {
            title: '调整值/单价(元)',
            dataIndex: 'price',
            width: '20%',
            align: 'center',
            type: 1,
            isEdit: true
        },
    ]);
    // 是否扣减套餐次数
    const isDeductTimes = ref(false);
    const isDeductTimesRemark = ref('');
    onMounted(() => {
        eventBus.on('validateStep', onValidateStep);
        
    });
    onUnmounted(() => {
        eventBus.off('validateStep', onValidateStep);
    });
    // 监听当前是第几步骤
    const onValidateStep = async (step) => {
        if (step == 3) {
            await onInitParam();
            // 调整回显
            if (route.query.type == 'edit') {
                echoData();
                if (route.query.type == 'edit') {
                    checkAdjustProjectIsDeductTimesEvent();
                }
            } else {
                if (route.query.type == 'draft') {
                    echoData();
                }
            }
        }
    };
    // 调整阶段判断该项目是否需要扣减次数
    const checkAdjustProjectIsDeductTimesEvent = async () => {
        const data = await checkAdjustProjectIsDeductTimes(sessionStorage.getItem('projectID'));
        console.log(data, '验证套餐扣减接口');
        if (data && data.code == 200) {
            if (data.data) {
                isDeductTimes.value = data.data?.flag;
                isDeductTimesRemark.value = data.data?.remark;
            }
        }
    };
    // 第三步参数默认值显示
    const onInitParam = async () => {
        const data = await getThreeStepParam(sessionStorage.getItem('projectID')
        );
        console.log(data);
        if (data && data.code == 200) {
            const requiredData = data.data;
            dataSource.value.map((ele) => {
                ele.default = requiredData[ele.dataIndex];
                if(ele.dataIndex == 'deviceWarranty') {
                    updateRangeYearEvent(requiredData[ele.dataIndex]);
                }
            });
            // 如果是调整，需要渲染新的特殊字段（名称为动态）
            if (route.query.type == 'edit') {
            }
            console.log('dataSource', dataSource.value);
        }
        nextTick(() => {
            columns.value = [
                {
                    title: '类型',
                    dataIndex: 'type',
                    width: '17%',
                    align: 'center',
                    customCell: (_, index) => {
                        if (index == 0) {
                            return {
                                rowSpan: 5,
                            };
                        } else if (index == 5) {
                            return {
                                rowSpan: 4,
                            };
                        } else if (index == 9) {
                            return {
                                rowSpan: 11,
                            };
                        } else if (index == 20) {
                            return {
                                rowSpan: 8,
                            };
                        } else if (index == 28) {
                            return {
                                rowSpan: 1,
                            };
                        } else {
                            return {
                                rowSpan: 0,
                            };
                        }
                        // if (index == 2) {
                        //     return {
                        //         rowSpan: 0
                        //     }
                        // }
                    },
                },
                {
                    title: '费用项',
                    dataIndex: 'name',
                    width: '28%',
                    align: 'center',
                },
                {
                    title: '单位',
                    dataIndex: 'unit',
                    width: '15%',
                    align: 'center',
                },
                {
                    title: '默认值/单价(元)',
                    dataIndex: 'default',
                    width: '20%',
                    align: 'center',
                },
                {
                    title: '调整值/单价(元)',
                    dataIndex: 'price',
                    width: '20%',
                    align: 'center',
                    type: 1,
                    isEdit: true
                },
            ];
        });
    };
    const filterDataSource = (data) => {
        const originData = JSON.parse(JSON.stringify(data));
        return route.query.type == 'edit' ? originData : originData.filter((ele) => !ele.isEdit);
    };
    // 根据字段名称查找对应的index
    const getIndex = (dataIndex) => {
        const index = dataSource.value.findIndex((ele) => ele.dataIndex == dataIndex);
        return index;
    };
    // 调整数据回显
    const echoData = async () => {
        const data = await selectProjectInfoById(sessionStorage.getItem('projectID'));
        if (data && data.code == 200) {
            const projectDetail = data.data.pvProjectFinanceRespDTO
                ? JSON.parse(JSON.stringify(data.data.pvProjectFinanceRespDTO))
                : {};
            dataSource.value.map((ele) => {
                // if (projectDetail[ele.dataIndex] !== ele.default) {
                //     ele.price = projectDetail[ele.dataIndex];
                // }
                ele.price = projectDetail[ele.dataIndex];
                // 草稿状态下还要把默认和调整值一致的去除掉
                if (route.query.type == 'draft') {
                    if (projectDetail[ele.dataIndex] == ele.default) {
                        ele.price = null;
                    }
                }
            });

            // 特殊字段（风电机组、塔筒、变压器、svg）数据处理
            const formatSpeParData = (sourceData, dataIndexArr) => {
                dataIndexArr.map((ele) => {
                    const index = getIndex(ele);
                    if (sourceData[ele]) {
                        const jsons = JSON.parse(sourceData[ele]);
                        if (index > -1) {
                            dataSource.value[index].isEdit = true;
                            dataSource.value[index].default = jsons.equipmentFee;
                            dataSource.value[index].name = jsons.name;
                            dataSource.value[index].unit = jsons.unit;
                            dataSource.value[index].price = jsons.equipmentFee;
                            // 主变压器数据需要单独设置最大值最小值；
                            if (ele == 'boosterJson') {
                                dataSource.value[index].min = jsons.equipmentFee * 0.95;
                                dataSource.value[index].max = jsons.equipmentFee * 1.1;
                            }
                        }
                    } else {
                        // 没有字段的，需要删除这条字段数据
                        dataSource.value.splice(index, 1);
                    }
                });
            };
            if (route.query.type == 'edit') {
                formatSpeParData(projectDetail, [
                    'windGroupJson',
                    'towerJson',
                    'boosterJson',
                    'svgGroupJson',
                ]);
            }
            projectDetailID.value = projectDetail.id || '';
        }
        console.log('fangshu', dataSource.value);
    };
    // 验证服务的有效性
    const checkPackage = async () => {
        let isEffective = true;
        await checkPackageEffective({
            companyId: sessionStorage.getItem('userId'),
            itemType: PACKAGE_TYPE.default, // 字符串类型 1风电可研 2光伏可研
        }).then((res) => {
            if (res.code == 200) {
                sessionStorage.setItem('isInitiate', res.data.isInitiate);
                if (res.data.isInitiate) {
                    // 可以去创建模板
                    isEffective = true;
                } else {
                    if (res.data.notInitiateType) {
                        messageType.value = res.data.notInitiateType;
                        TipsDialogVisible.value = true;
                    }
                    isEffective = false;
                }
            } else {
                // isShowLoading.value = false;
            }
        });
        return isEffective;
    };
    // 传数据到后台
    const postData = async (type, params) => {
        formLoading.value = true;
        try {
            const data =
                route.query.type == 'edit'
                    ? await updateFormData(params)
                    : await saveFormData(params);
            if (data && data.code == 200) {
                formLoading.value = false;
                message.success('操作成功！');
                if (type == 1) {
                    // 保存成草稿停留在当前页面
                } else {
                    router.push({ path: '/light/reportResult' });
                }
            } else {
                formLoading.value = false;
                message.error(data.msg);
            }
        } catch (error) {
            formLoading.value = false;
        }
    };
    // 提交数据前数据处理操作
    const onBeforeSubmitForm = async (type) => {
        // 用户行为日志--草稿,生成项目
        if (type == 1) {
            gtag('event', 'save_draft', {
                user_info_id: sessionStorage.getItem('userId'),
                project_info_id: sessionStorage.getItem('projectID'),
            });
        } else {
            gtag('event', 'build_project', {
                user_info_id: sessionStorage.getItem('userId'),
                project_info_id: sessionStorage.getItem('projectID'),
            });
        }
        const formateResult = {
            projectId: sessionStorage.getItem('projectID'),
        };
        editTableRef.value.dataList.map((ele) => {
            formateResult[ele.dataIndex] = ele.price || ele.price == 0 ? ele.price : ele.default;
        });
        const params = {
            id: sessionStorage.getItem('projectID'),
            step: 3,
            uniqueIdentification: sessionStorage.getItem('uniqueIdentification'),
            projectAdjustOneStepFlag: false,
            projectAdjustFlag: route.query.type == 'edit',
            sunProjectDetail: formateResult,
        };
        // 如果是草稿传特殊字段
        type == 1 && (params.isDraft = true);
        if (route.query.type == 'draft') {
            params.sunProjectDetail.id = projectDetailID.value;
        }
        if (route.query.type == 'edit') {
            params.projectId = sessionStorage.getItem('projectID');
            params.sunProjectDetail.id = projectDetailID.value;
            params.projectAdjustId = sessionStorage.getItem('originProjectID');
            const formatSpeParParams = (parsArr) => {
                parsArr.map((ele) => {
                    const index = getIndex(ele);
                    if (index > -1) {
                        params.sunProjectDetail[ele] = JSON.stringify({
                            name: dataSource.value[index].name,
                            equipmentFee:
                                dataSource.value[index].price || dataSource.value[index].default,
                            unit: dataSource.value[index].unit,
                        });
                    }
                });
            };
            // 四个字段特殊处理
            formatSpeParParams(['windGroupJson', 'towerJson', 'boosterJson', 'svgGroupJson']);
        } else {
            // 新增创建、草稿编辑不传这几个特殊字段数据
            ['windGroupJson', 'towerJson', 'boosterJson', 'svgGroupJson'].forEach((ele) => {
                delete params.sunProjectDetail[ele];
            });
        }
        params.obsPath = sessionStorage.getItem('obsFilePath');
        console.log(params);
        debugger;
        if (route.query.type == 'edit') {
            if (isDeductTimes.value) {
                Modal.confirm({
                    title: '套餐提示',
                    content: h(
                        'div',
                        {
                            class: 'softdog-modal-content',
                        },
                        [
                            h(
                                'p',
                                '当前调整项目因达到扣减次数条件将扣减服务1个次数，确定调整该项目么？',
                            ),
                            h(
                                'p',
                                {
                                    style: 'margin-top: 10px',
                                },
                                '满足条件为：',
                            ),
                            h(
                                'p',
                                {
                                    class: 'softdog-modal-description',
                                    style: 'margin-top: 1px',
                                },
                                isDeductTimesRemark.value,
                            ),
                            h(
                                'p',
                                {
                                    class: 'softdog-modal-description',
                                },
                                [
                                    '',
                                    h('div', [
                                        '平台客服：',
                                        h(
                                            'span',
                                            {
                                                class: 'self-bar',
                                            },
                                            '155-6259-8617',
                                        ),
                                    ]),
                                ],
                            ),
                        ],
                    ),
                    okText: '确定调整',
                    cancelText: '再考虑下',
                    onOk() {
                        // 调整项目需要先判断是否需要扣除套餐次数，如果需要扣除，要验证套餐是否有效，有效再去发送数据到后台
                        checkPackage().then((res) => {
                            if (res) {
                                postData(type, params);
                            }
                        });
                    },
                    onCancel() {
                        console.log('Cancel');
                    },
                });
            } else {
                postData(type, params);
            }
        } else {
            postData(type, params);
        }
    };
</script>
<style lang="scss" scoped>
    @import './ThreeStep.scss';
</style>
