import { publicService } from '@/api/base';
// 第三步参数默认值获取
export async function getThreeStepParam(data) {
    return await publicService.post('/web/project/getThreeStepSunParam?projectId=' + data);
}
// // 提交第三步参数表单数据
// export async function submitThreeStepParam(data) {
//     return await publicService.post('/web/project/saveOrUpdateProject', data);
// }
// 提交手机号
export async function submitPhone(data) {
    return await publicService.put('/web/project/update/phone', data);
}
// 项目状态
export async function getProjectStatus(data) {
    return await publicService.get(`/web/project/status/${data}`);
}
// 提交版本信息
export async function submitVersion(data) {
    return await publicService.post('/web/project/updateProjectVersionById', data);
}