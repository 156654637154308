import { message } from 'ant-design-vue';
import { requestConfig } from './base';
import { refreshToken, isRefreshRequest } from './modules/refreshToken';
import router from '@/router/router.js';
// import { logger } from '@/utils/logger/logger';
// import { userStore } from '@/utils/store/module/userStore';

export async function requestInterceptor(config) {
    // console.log(config);
    config.headers = config.headers || {};
    config.headers['Authorization'] = sessionStorage.getItem('accessToken') || '';
    config.headers['Content-Type'] = config.__isModifyHeader
                    ? 'multipart/form-data'
                    : 'application/json';

    return config;
}
export async function responseInterceptor(response, val) {
    const url = response.config.baseURL + response.config.url;
    if (response.status === 200) {
        if (response.data.code === 401 && !isRefreshRequest(response.config)) {
            const isSuccess = await refreshToken();
            if (isSuccess) {
                
                response.config.headers['Authorization'] =
                    sessionStorage.getItem('accessToken') || '';
                const resp = val.request(response.config);
                return resp;
            } else {
                removeLoading();
                // console.log('没有登录，跳转到登录页');
                // 确实是没有登录
                router.push('/wh/login');
            }
            return false;
        }
        if(response.data.code === 400 || response.data.code === 100300002 ) {
            return response.data;
        }
        if (!(response.data.code === 200 || response.data.code === 1)) {
            removeLoading();
            message.error(response.data.message || response.data.msg).then((r) => r);
        }
        if (response.data.code == 401) {
            removeLoading();
            message.error('登录过期，请重新登录');
            setTimeout(() => {
                window.close();
            }, 2000);
        }
        return response.data;
    } else {
        removeLoading();
        if (response.status === 500) {
            message.error('服务异常');
        }
        if (response.status === 401) {
            return Promise.reject(new Error('Login Invalid'));
        } else {
            return Promise.reject(new Error('Network Error'));
        }
    }
}

// 处理接口报错loading效果不消失情况
const removeLoading = () => {
    const loading = document.querySelector('#__LOADING_PARENT__');
    if (!loading) return;
    loading.remove()
}

export function responseInterceptorRejected(err) {
    removeLoading();
    console.log('请求错误', err.config.__isRefreshToken);
    return Promise.reject(err);
}
