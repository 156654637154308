

const requestConfig = $config_api;

export function getEnvUrl() {
    const env = process.env.NODE_ENV;

    console.log('config.ts:28 ==> env-->', env);

    return requestConfig[env];
}
