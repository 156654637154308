const fileIconList = {
    'ppt':'ppt',
    'pdf':'pdf',
    'txt':'txt',
    'docx':'doc',
    'doc':'doc',
    'xls':'exc',
    'xlsx':'exc',
    'zip':'txt',
    'rar':'txt'
  }
  // const obsClientInfo = {
  //   'ak': 'YGSPIDOSBOOSQOT8HKBV',
  //   'sk': 'SjF5OLvO3SyMuuenrTCGyK7jYLLNmdnPJPR2nuQx',
  //   'bucketname': 'henry-test1',
  //   'endPoint': 'https://obs.cn-east-3.myhuaweicloud.com' // https://obs.cn-north-4.myhuaweicloud.com 北京四
  // }
  // const obsClientInfo = {
  //   // 'ak': 'XBZBISJOUET2SPCEAU2M',
  //   // 'sk': 'omiyFXdQaMTBwykLRxoPHP2bkJezIgPE0z0MCAK2',
  //   // 'bucketname': 'whln',
  //   'bucketname':  process.env.NODE_ENV == "production" ? 'whln-prod-private': 'whln',
  //   'endPoint': 'https://obs.cn-north-4.myhuaweicloud.com', // https://obs.cn-north-4.myhuaweicloud.com 北京四
  //   'domain': 'whln.obs.cn-north-4.myhuaweicloud.com',
  //   'publicBucketname': process.env.NODE_ENV == "production" ? 'whln-prod-public': 'whln-public',
  // }
  const obsClientInfo = {
    'bucketname': $config_obs[process.env.NODE_ENV]['bucketname'],
    'endPoint': $config_obs[process.env.NODE_ENV]['endPoint'], // https://obs.cn-north-4.myhuaweicloud.com 北京四
    'domain': $config_obs[process.env.NODE_ENV]['domain'],
    'publicBucketname': $config_obs[process.env.NODE_ENV]['publicBucketname'],
  }
  // 校验套餐 1.一键可研 2AI找风资源 3项目建议书
  const PACKAGE_TYPE = {
    'default':  1,
    'auto': 2,
    'proposal': 3,
    'lightDefault': 4,
  }
  // 天地图Key
  const tdKey = $config_tdKey;
  const dogKey = 1;
  // 机密狗配置环境文件
  const configurationPath = {
    1: 'https://whln-prod-public.obs.cn-north-4.myhuaweicloud.com/configuration/%E8%BF%90%E8%A1%8C%E7%A8%8B%E5%BA%8F%28x86%29.rar',
    2: 'https://whln-prod-public.obs.cn-north-4.myhuaweicloud.com/configuration/%E8%BF%90%E8%A1%8C%E7%A8%8B%E5%BA%8F%28x64%29.rar',
    3: 'https://whln-prod-public.obs.cn-north-4.myhuaweicloud.com/configuration/%E8%BF%90%E8%A1%8C%E7%A8%8B%E5%BA%8F%28x64%29.rar'
  }
  const templatePath = {
    // 场区点位模板
    point: 'https://whln-prod-public.obs.cn-north-4.myhuaweicloud.com/template/%E5%9C%BA%E5%8C%BA%E5%9D%90%E6%A0%87%E6%A8%A1%E6%9D%BF.xlsx',
    // 风机点位模板
    fan: 'https://whln-prod-public.obs.cn-north-4.myhuaweicloud.com/template/%E9%A3%8E%E6%9C%BA%E5%9D%90%E6%A0%87%E6%A8%A1%E6%9D%BF.xlsx',
    // 气象数据导入模板
    weather: 'https://whln-prod-public.obs.cn-north-4.myhuaweicloud.com/template/%E6%B0%94%E8%B1%A1%E6%95%B0%E6%8D%AE%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx'
  }
  // 用户操作指南文件
  const guideReportPath = 'https://whln-prod-public.obs.cn-north-4.myhuaweicloud.com/configuration/%E4%B8%80%E9%94%AE%E9%A3%8E%E7%94%B5%E5%8F%AF%E7%A0%94%E4%BA%A7%E5%93%81%E6%93%8D%E4%BD%9C%E8%AF%B4%E6%98%8E%E6%89%8B%E5%86%8C.pdf';
  // pdfview插件配置文件
  const pdfViewConfigurationPath = 'https://whln-prod-public.obs.cn-north-4.myhuaweicloud.com/configuration/viewer.properties';
export {
    fileIconList,
    obsClientInfo,
    dogKey,
    configurationPath,
    templatePath,
    guideReportPath,
    pdfViewConfigurationPath,
    PACKAGE_TYPE,
    tdKey
}
