const isOnLine = (x, y, points) => {
    const pointsX = [];
    const pointsY = [];
    points.forEach(item => {
        pointsX.push(item[0]);
        pointsY.push(item[1]);
    })
    const indexX = pointsX.findIndex(ele => ele == x);
    const indexY = pointsY.findIndex(ele => ele == y);
    if (indexX > -1 && indexY > -1) {
        return true;
    } else {
        return false;
    }
}
export function checkPointInArea([x, y], points) {
    let nCross = 0;
    let ans = false;
    if (points.length < 3) {
        return ans;
    }
    if (isOnLine(x, y, points)) {
        return true;
    }
    
    for (let i = 0, L = points.length; i < L; i++) {
        const point1 = points[i];
        const point2 = points[(i + 1) % L]

        const [x1, y1] = point1;
        const [x2, y2] = point2;

        if (y < Math.min(y1, y2) || y >= Math.max(y1, y2)) { // 限定 y 在  y1 及y2之间
            continue;
        }
        // 在 point1及point2确定的直线上，根据待测点的y，求出交点坐标x
        // 直线方程。两点式(y-y2)/(y1-y2) = (x-x2)/(x1-x2)
        let crossoverX = (y - y2) * (x1 - x2) / (y1 - y2) + x2;
        if (y1 === y2) { // 水平边的交点即为待测点的坐标 暂时先不管了 感兴趣可以自己处理一下
            // crossoverX = x;
            continue;
        }
        if (crossoverX > x) { // 只考虑一个方向
            // ans = !ans;
            nCross++;
        }

    }
    return nCross % 2 == 1;
}