<template>
    <div class="three-component" v-loading="[formLoading, true]">
        <div class="illustrate">
            <iconpark-icon class="illustrate-icon" :size="16" name="jinggao"></iconpark-icon>
            <span
                >说明：系统提供以下费用项的默认值，您可根据公司项目进行相关费用的单价调整，也可不进行调整</span
            >
        </div>
        <EditTable :columns="columns" :dataSource="dataSource"></EditTable>
        <div class="handle-submit-group">
            <a-button
                v-if="route.query.type !== 'edit'"
                v-softDog="{
                    callback: onSubmitBeforeForm,
                    params: 1,
                    packageType: PACKAGE_TYPE.proposal,
                }"
                >保存草稿</a-button
            >
            <a-button
                class="step-next"
                v-softDog="{
                    callback: onSubmitBeforeForm,
                    params: 2,
                    packageType: PACKAGE_TYPE.proposal,
                }"
                >一键生成</a-button
            >
        </div>
        <projectPackage
            :TipsDialogVisible="TipsDialogVisible"
            :messageType="messageType"
            @changeShowKey="TipsDialogVisible = false"
        ></projectPackage>
    </div>
</template>
<script setup>
    import { ref, reactive, onMounted, onUnmounted, h } from 'vue';
    import { useRoute, useRouter } from 'vue-router';
    import EditTable from '@/components/editTable/EditTable.vue';
    import {
        getThreeStepParam, // 获取第三步骤默认值
    } from '@/api/modules/wind/threeStep';
    import { checkAdjustProjectIsDeductTimes } from '@/api/modules/project';
    import {
        saveFormData,
        updateFormData,
        selectProjectInfoById,
    } from '@/api/modules/wind/proposalStep.js';
    import { message } from 'ant-design-vue';
    import eventBus from '@/utils/eventBus';
    import projectPackage from '@/components/projectPackage/projectPackage.vue';
    import { checkPackageEffective } from '@/api/modules/reportIndex.js';
    import { PACKAGE_TYPE } from '@/assets/js/static.js';
    import { Modal } from 'ant-design-vue';
    // 提交loading
    const formLoading = ref(false);
    const route = useRoute();
    const router = useRouter();
    const TipsDialogVisible = ref(false);
    const messageType = ref(1);
    // 数据源常量
    const dataSource = ref([
        {
            name: '项目资金比例',
            dataIndex: 'fundingRatio',
            unit: '%',
            default: 20,
            price: null,
            min: 20,
            max: 100,
        },
        {
            name: '单位千瓦静态投资(不含储能)',
            dataIndex: 'unitStaticInvestment',
            unit: '元/kW',
            default: null,
            price: null,
            min: 4000,
            max: 10000,
            isParseInt: true,
            isShow: true,
        },
        {
            name: '储能单位静态投资',
            dataIndex: 'investmentEnergyStorageUnits',
            unit: '元/kW',
            default: null,
            price: null,
            min: 700,
            max: 1200,
            isParseInt: true,
            isShow: true,
        },
        {
            name: '定员人数',
            dataIndex: 'fixNumPerson',
            unit: '人',
            default: 12,
            price: null,
            min: 1,
            max: 100,
            isParseInt: true,
        },
        {
            name: '人工工资（不含福利）',
            dataIndex: 'wages',
            unit: '人/年',
            default: 80000,
            price: null,
            min: 50000,
            max: 1000000,
        },
        {
            name: '长期贷款利率',
            dataIndex: 'longLoanRate',
            unit: '%',
            default: 4.9,
            price: null,
            min: 0,
            max: 20,
        },
        {
            name: '短期贷款利率',
            dataIndex: 'shortLoanRate',
            unit: '%',
            default: 3.2,
            price: null,
            min: 0,
            max: 20,
        },
        {
            name: '风机质保期',
            dataIndex: 'deviceWarranty',
            unit: '年',
            default: 5,
            price: null,
            min: 1,
            max: 50,
        },
        {
            name: '材料费（质保期内）',
            dataIndex: 'warrantyInMaterial',
            unit: '元/kM',
            default: 5,
            price: null,
            min: 0,
            max: 1000,
        },
        {
            name: '材料费（质保期外）',
            dataIndex: 'warrantyOutMaterial',
            unit: '元/kM',
            default: 11,
            price: null,
            min: 0,
            max: 1000,
        },
        {
            name: '其他费',
            dataIndex: 'otherFee',
            unit: '元/kM',
            default: 30,
            price: null,
            min: 0,
            max: 1000,
        },
        {
            name: '保险费率',
            dataIndex: 'safeRate',
            unit: '%',
            default: 0.25,
            price: null,
            min: 0,
            max: 1,
        },
        {
            name: '修理费（质保期内）',
            dataIndex: 'warrantyInFix',
            unit: '元/kM',
            default: 16,
            price: null,
            min: 0,
            max: 1000,
        },
        {
            name: '修理费（质保期外）',
            dataIndex: 'warrantyOutFix',
            unit: '元/kM',
            default: 40,
            price: null,
            min: 0,
            max: 1000,
        },
        {
            name: '折旧年限',
            dataIndex: 'depreciationPeriod',
            unit: '年',
            default: 20,
            price: null,
            min: 5,
            max: 20,
        },
        {
            name: '折旧残值',
            dataIndex: 'depreciationResidual',
            unit: '%',
            default: 5,
            price: null,
            min: 0,
            max: 5,
        },
        // {
        //     name: '永久征地费',
        //     dataIndex: 'landAcquisitionFee',
        //     unit: '元/亩',
        //     default: 246790,
        //     price: null,
        //     min: 25000,
        //     max: 800000,
        // },
        // {
        //     // name: '土地经济补偿费',
        //     name: '永久征地补偿费',
        //     dataIndex: 'landCompensation',
        //     unit: '元/亩/年',
        //     default: 213440,
        //     price: null,
        //     min: 2000,
        //     max: 250000,
        // },
    ]);
    // 详情唯一标识id
    const projectDetailID = ref('');
    // 表头常量
    const columns = ref([
        {
            title: '费用项',
            dataIndex: 'name',
            width: '28%',
            align: 'center',
        },
        {
            title: '单位',
            dataIndex: 'unit',
            width: '22%',
            align: 'center',
        },
        {
            title: '调整值/单价(元)',
            dataIndex: 'price',
            width: '25%',
            align: 'center',
            isEdit: true,
            type: 1,
        },
    ]);
    // 是否扣减套餐次数
    const isDeductTimes = ref(false);
    const isDeductTimesRemark = ref('');
    // 调整阶段判断该项目是否需要扣减次数
    const checkAdjustProjectIsDeductTimesEvent = async () => {
        const data = await checkAdjustProjectIsDeductTimes(sessionStorage.getItem('projectID'));
        console.log(data, '验证套餐扣减接口');
        if (data && data.code == 200) {
            if (data.data) {
                isDeductTimes.value = data.data?.flag;
                isDeductTimesRemark.value = data.data?.remark;
            }
        }
    };
    onMounted(() => {
        eventBus.on('validateStep', onValidateStep);
    });
    onUnmounted(() => {
        eventBus.off('validateStep');
    });
    // 监听当前是第几步骤
    const onValidateStep = async (step) => {
        if (step == 2) {
            await onInitParam();
            // 调整回显
            if (route.query.type == 'edit') {
                columns.value = [
                    {
                        title: '费用项',
                        dataIndex: 'name',
                        width: '28%',
                        align: 'center',
                    },
                    {
                        title: '单位',
                        dataIndex: 'unit',
                        width: '22%',
                        align: 'center',
                    },
                    {
                        title: '调整值/单价(元)',
                        dataIndex: 'price',
                        width: '25%',
                        align: 'center',
                        isEdit: true,
                        type: 1,
                    },
                ];
                echoData();
                if (route.query.type == 'edit') {
                    checkAdjustProjectIsDeductTimesEvent();
                }
            } else {
                columns.value = [
                    {
                        title: '费用项',
                        dataIndex: 'name',
                        width: '28%',
                        align: 'center',
                    },
                    {
                        title: '单位',
                        dataIndex: 'unit',
                        width: '22%',
                        align: 'center',
                    },
                    {
                        title: '默认值/单价(元)',
                        dataIndex: 'default',
                        width: '25%',
                        align: 'center',
                    },
                    {
                        title: '调整值/单价(元)',
                        dataIndex: 'price',
                        width: '25%',
                        align: 'center',
                        isEdit: true,
                        type: 1,
                    },
                ];
                if (route.query.type == 'draft') {
                    echoData();
                }
            }
        }
    };
    // 第三步参数默认值显示
    const onInitParam = async () => {
        const data = await getThreeStepParam({
            id: sessionStorage.getItem('projectID'),
        });
        console.log(data, '项目建议书第三部默认参数');
        if (data && data.code == 200) {
            const requiredData = data.data;
            dataSource.value.map((ele, eleIndex) => {
                // if (ele.dataIndex == 'unitStaticInvestment') {
                //     ele.default = requiredData['adjustAfterUnitStaticInvestment'];
                //     // ele.price = requiredData['adjustAfterUnitStaticInvestment'];
                //     console.log(ele, '静态投资');
                // } else {
                //     ele.default = requiredData[ele.dataIndex];
                // }
                ele.default = requiredData[ele.dataIndex];
            });
            console.log(dataSource.value, '结果');
            // 如果是调整，需要渲染新的特殊字段（名称为动态）
            if (route.query.type == 'edit') {
            }
        }
    };
    const filterDataSource = (data) => {
        console.log(data.filter((ele) => ele.default || ele.default == 0));
        return route.query.type == 'edit'
            ? data.filter((ele) => ele.default || ele.default == 0)
            : data.filter((ele) => !ele.isEdit);
    };
    // 根据字段名称查找对应的index
    const getIndex = (dataIndex) => {
        const index = dataSource.value.findIndex((ele) => ele.dataIndex == dataIndex);
        return index;
    };
    // 调整数据回显
    const echoData = async () => {
        const data = await selectProjectInfoById({ id: sessionStorage.getItem('projectID') });
        if (data && data.code == 200) {
            const projectDetail = data.data.projectDetailDTO || {};
            const originDataSource = [...dataSource.value];
            originDataSource.map((ele) => {
                // if (projectDetail[ele.dataIndex] !== ele.default) {
                //     ele.price = projectDetail[ele.dataIndex];
                // } else {
                //     ele.price = null;
                // }
                ele.price = projectDetail[ele.dataIndex];
                // 草稿状态下还要把默认和调整值一致的去除掉
                if (route.query.type == 'draft') {
                    if (projectDetail[ele.dataIndex] == ele.default) {
                        ele.price = null;
                    }
                }
                if ((ele.dataIndex == 'unitStaticInvestment' || ele.dataIndex == 'investmentEnergyStorageUnits') && !projectDetail[ele.dataIndex]) {
                    console.log('进来，', ele.price, ele.default);
                    ele.price = ele.default;
                } 
            });
            dataSource.value = [...originDataSource];
            console.log(dataSource.value, '回显的结果');
            projectDetailID.value = projectDetail.id || '';
        }
    };
    // 验证服务的有效性
    const checkPackage = async () => {
        let isEffective = true;
        await checkPackageEffective({
            companyId: sessionStorage.getItem('userId'),
            itemType: PACKAGE_TYPE.proposal, // 字符串类型 1风电可研 2自动布机 3项目建议书
        }).then((res) => {
            if (res.code == 200) {
                sessionStorage.setItem('isInitiate', res.data.isInitiate);
                if (res.data.isInitiate) {
                    // 可以去创建模板
                    isEffective = true;
                } else {
                    if (res.data.notInitiateType) {
                        messageType.value = res.data.notInitiateType;
                        TipsDialogVisible.value = true;
                    }
                    isEffective = false;
                }
            } else {
                // isShowLoading.value = false;
            }
        });
        return isEffective;
    };
    // 提交数据给后台
    const postData = async (type, params) => {
        formLoading.value = true;
        try {
            const data = await updateFormData(params);
            console.log(data);
            if (data && data.code == 200) {
                formLoading.value = false;
                message.success('操作成功！');
                if (type == 1) {
                    // 保存成草稿停留在当前页面
                } else {
                    router.push({ path: '/autoResult' });
                }
            } else {
                formLoading.value = false;
                message.error(data.msg);
            }
        } catch (error) {
            formLoading.value = false;
        }
    };
    // 提交数据操作
    const onSubmitBeforeForm = async (type) => {
        // 用户行为日志--草稿,生成项目
        if (type == 1) {
            gtag('event', 'save_draft', {
                user_info_id: sessionStorage.getItem('userId'),
                project_info_id: sessionStorage.getItem('projectID'),
            });
        } else {
            gtag('event', 'build_project', {
                user_info_id: sessionStorage.getItem('userId'),
                project_info_id: sessionStorage.getItem('projectID'),
            });
        }
        const formateResult = {
            projectId: sessionStorage.getItem('projectID'),
        };
        dataSource.value.map((ele) => {
            if (ele.default || ele.default == 0) {
                formateResult[ele.dataIndex] =
                    ele.price || ele.price == 0 ? ele.price : ele.default;
            }
        });
        const params = {
            id: sessionStorage.getItem('projectID'),
            proposalStep: 2,
            uniqueIdentification: sessionStorage.getItem('uniqueIdentification'),
            projectAdjustOneStepFlag: false,
            projectAdjustFlag: route.query.type == 'edit',
            projectDetail: formateResult,
            proposalId: sessionStorage.getItem('projectID'),
            isDraft: type == 1,
        };
        // 如果是草稿传特殊字段
        if (route.query.type == 'draft') {
            params.projectDetail.id = projectDetailID.value;
        }
        if (route.query.type == 'edit') {
            params.projectId = sessionStorage.getItem('projectID');
            params.projectDetail.id = projectDetailID.value;
            params.projectAdjustId = sessionStorage.getItem('originProjectID');
            const formatSpeParParams = (parsArr) => {
                parsArr.map((ele) => {
                    const index = getIndex(ele);
                    if (index > -1) {
                        params.projectDetail[ele] = JSON.stringify({
                            name: dataSource.value[index].name,
                            equipmentFee:
                                dataSource.value[index].price || dataSource.value[index].default,
                            unit: dataSource.value[index].unit,
                        });
                    }
                });
            };
            // 四个字段特殊处理
            formatSpeParParams(['windGroupJson', 'towerJson', 'boosterJson', 'svgGroupJson']);
        } else {
            // 新增创建、草稿编辑不传这几个特殊字段数据
            ['windGroupJson', 'towerJson', 'boosterJson', 'svgGroupJson'].forEach((ele) => {
                delete params.projectDetail[ele];
            });
        }
        params.obsPath = sessionStorage.getItem('obsFilePath');
        console.log(params);
        if (route.query.type == 'edit') {
            if (isDeductTimes.value) {
                // 调整项目需要先判断是否需要扣除套餐次数，如果需要扣除，要验证套餐是否有效，有效再去发送数据到后台
                Modal.confirm({
                    title: '套餐提示',
                    content: h(
                        'div',
                        {
                            class: 'softdog-modal-content',
                        },
                        [
                            h(
                                'p',
                                '当前调整项目因达到扣减次数条件将扣减服务1个次数，确定调整该项目么？',
                            ),
                            h(
                                'p',
                                {
                                    style: 'margin-top: 10px',
                                },
                                '满足条件为：',
                            ),
                            h(
                                'p',
                                {
                                    class: 'softdog-modal-description',
                                    style: 'margin-top: 1px',
                                },
                                isDeductTimesRemark.value,
                            ),
                            h(
                                'p',
                                {
                                    class: 'softdog-modal-description',
                                },
                                [
                                    '',
                                    h('div', [
                                        '平台客服：',
                                        h(
                                            'span',
                                            {
                                                class: 'self-bar',
                                            },
                                            '155-6259-8617',
                                        ),
                                    ]),
                                ],
                            ),
                        ],
                    ),
                    okText: '确定调整',
                    cancelText: '再考虑下',
                    onOk() {
                        // debugger;
                        // 调整项目需要先判断是否需要扣除套餐次数，如果需要扣除，要验证套餐是否有效，有效再去发送数据到后台
                        checkPackage().then((res) => {
                            if (res) {
                                postData(type, params);
                            }
                        });
                    },
                    onCancel() {
                        console.log('Cancel');
                    },
                });
            } else {
                // debugger
                postData(type, params);
            }
        } else {
            // debugger
            postData(type, params);
        }
        // return false;
    };
</script>
<style lang="scss" scoped>
    @import './second.scss';
</style>
