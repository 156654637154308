<template>
    <div class="edit-table-component">
        <a-table :pagination="false" :columns="props.columns" :data-source="dataList" bordered>
            <template #bodyCell="{ column, text, record }">
                <template v-if="column.isEdit">
                    <a-input-number
                        v-if="column.type"
                        :min="record.min"
                        :max="record.max"
                        v-model:value="record[column.dataIndex]"
                        :controls="false"
                        style="margin: -5px 0"
                        :parser="value => record.isParseInt ? parseInt(value) : value"
                        @blur="checkRangeType(column, record)"
                    />
                     
                    <a-input v-else v-model:value="record[column.dataIndex]" style="margin: -5px 0" />
                </template>
            </template>
        </a-table>
    </div>
</template>
<style scoped lang="scss">
    @import './EditTable.scss';
</style>
<script setup>
    import { ref, watch } from 'vue';
    import { useRoute } from 'vue-router';
    const emits = defineEmits(['updateRangeYear']);
    const route = useRoute();
    const props = defineProps({
        columns: {
            type: Array,
            default: () => {
                return [];
            },
        },
        dataSource: {
          type: Array,
            default: () => {
                return [];
            },
        }
    });
    const dataList = ref([]);
    const checkRangeType = (type, value) => {
        // 如果是光伏组件质保期，需要更新
        if (value.dataIndex == "deviceWarranty") {
            emits('updateRangeYear', value.price);
        }
    }
    watch(() => props.dataSource, (newValue, oldValue) => {
        console.log('又进来了？');
        const arrs = [];
        props.dataSource && props.dataSource.forEach(ele => {
                if (ele.default || ele.default == 0) {
                    arrs.push(ele);
                }
        });
        // debugger
        dataList.value = arrs;
    }, {
        deep: true,
        immediate: true
    })
    defineExpose({
        dataList
    })
</script>
