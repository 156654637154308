// 定义样式对象
const themes = {
    light: {
      '--theme': '#FF8411',
      '--themeLight': '#FDF9F0',
      '--themeGradation': 'linear-gradient(270deg, #F4C62A 0%, #FF8411 100%)',
      '--themeProgress': '#F8EBC8'
    },
    dark: {
      '--theme': '#036B64',
      '--themeLight': '#f2f7f7',
      '--themeGradation': 'linear-gradient(90deg, #049087 0%, #3FAFA8 100%)',
      '--themeProgress': '#E5F0EF'
    },
  };
  
  // 公共方法：根据主题对象设置CSS变量
const setThemeVariables = (theme)=> {
    const bodyStyle = window.document.getElementsByTagName('body')[0].style;
    Object.keys(theme).forEach(key => {
      bodyStyle.setProperty(key, theme[key]);
    });
}
export {
    themes,
    setThemeVariables
}
  
  