import { createRouter, createWebHistory } from 'vue-router';
import { routes } from '@/router/config';
import axios from 'axios';

const routers = createRouter({
    history: createWebHistory('/'),
    routes: routes,
});
// routers.beforeEach(async(to, from, next) => {
//     console.log(to,'路由周期sss',from);
//     // await versionCheck(to);
//     next();
// });
const versionCheck = async(to) => {
    // if (process.env.NODE_ENV === 'development') return;
    console.log(404, window.location.origin + '/version.json');
    const response = await axios.get(window.location.origin + '/version.json');
    console.log('当前版本', response, __APP_VERSION__);
    if (__APP_VERSION__ !== response.data.version) {
        console.log('有新版本，5秒后自动刷新页面', window.location);
        window.location.href = window.location.origin + to.fullPath;
        // setTimeout(() => {
        //     window.location.reload()
        // }, 5000)
    }
}
let toPath;
routers.beforeEach((to, from, next) => {
    toPath = to;
    // console.log(to,'路由周期',from);
    next();
});
routers.onError(error => {
    if (error.message.includes("Failed to fetch dynamically imported module")) {
        window.location.href = window.location.origin + toPath.fullPath;
    }
});
export default routers;
