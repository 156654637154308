<template>
    <div class="left-indent" v-loading="[fanPointLoading, true]">
        <p class="line-download">
            <span>点击这里先</span>
            <a href="javascript:;" @click.stop="downLoadFile(templatePath['fan'])">
                下载导入模板.xlsx
            </a>
        </p>
        <Upload
            ref="fanLocationRef"
            :fileTypes="'xlsx'"
            v-model:defaultFiles="fanFile"
            :fileTypeMessage="'请上传.xlsx格式模板数据文件'"
            :isImmediatelyUpload="false"
            @upFileList="upFileList"
            :disabled="fanDisabled"
        >
            <template v-slot:tip>
                <span class="illustrate">上传已填写的模板数据</span>
            </template>
        </Upload>
        <template v-if="fanLocationErrorInfos.length">
            <p class="error-info-title">
                <InfoCircleOutlined style="color: #fe830f" />
                导入数据存在以下异常，请修正后重新上传
            </p>
            <ul class="error-info-list">
                <template v-for="(item) in fanLocationErrorInfos" :key="index">
                    <li v-if="item">
                        {{ item }}
                    </li>
                </template>
            </ul>
        </template>
    </div>
</template>
<script setup>
    import { ref, watch, onBeforeMount, onBeforeUnmount  } from 'vue';
    import { templatePath } from '@/assets/js/static.js';
    import Upload from '@/components/steps/components/upload/Upload.vue';
    import eventBus from '@/utils/eventBus';
    import { useObsUpload } from '@/components/obsClient/obsUpload';
    import { InfoCircleOutlined } from '@ant-design/icons-vue';
    import {
        checkPointInArea, // 检测风机点位文件
    } from '@/api/modules/wind/firstStep';
    import usefieldFanDataStore from '@/pinia/modules/fieldFanData';
    let fieldFanDataStore = usefieldFanDataStore();
    const props = defineProps({
        pointType: {
            type: Number,
            default: 1
        },
        handleDisabled: {
            type: Boolean,
            default: false
        },
    })
    const emits = defineEmits(['checkFormValidateFields']);
    const fanDisabled = ref(true);
    const fanLocationRef = ref(null);
    const obsUpload = useObsUpload('fd/');
    const fanPointLoading = ref(false);
    const fanLocationErrorInfos = ref([]);
    const fanFile = ref([]);
    const fanPointFileList = ref([]);
    const fanPointFileInfo = ref([]);
    const fanPointFileData = ref([]);
    // 风机点位文件上传
    const upFileList = async (file) => {
        fanPointFileList.value = [file];
        fanPointFileInfo.value = [];
        if (!fieldFanDataStore.pointData.length) {
            message.error('请先上传场区坐标文件');
            return false;
        }
        fanPointLoading.value = true;
        const formData = new FormData();
        formData.append('file', file);
        formData.append('coordinates', JSON.stringify(fieldFanDataStore.pointData));
        formData.append('uniqueIdentification', fieldFanDataStore.uniqueIdentificationUUID);

        try {
            const data = await checkPointInArea(formData);
            fanPointFileData.value = '[]';
            fanLocationErrorInfos.value = [];
            fanFile.value = [];
            fieldFanDataStore.fanFile = [];
            if (data && data.code === 200) {
                fanPointLoading.value = false;
                // 校验成功！
                if (data.data) {
                    fanPointFileData.value =
                        typeof data.data === 'string' ? JSON.parse(data.data) : data.data;
                        eventBus.emit('uploadFan', fanPointFileData.value);
                    fanFile.value.push(file);
                    fieldFanDataStore.fanFile.push(file);
                    
                    // 上传至华为云获取文件线上路径
                    let uploadResult = await obsUpload.uploadFile(file, sessionStorage.getItem('obsFilePath'));
                    console.log('+++++++++++++++++');
                    fanPointFileInfo.value.push({
                        fileName: file.name,
                        filePath: uploadResult.obsPath,
                        suffix: '.' + file.name.split('.')[file.name.split('.').length - 1],
                    });
                }
            } else {
                fanPointLoading.value = false;
                // 接口失败情况，存在校验信息情况下
                fanPointFileList.value = [];
                fanPointFileData.value = [];
                fanLocationRef.value.deleteItem();
                fieldFanDataStore.fanFile = [];
                if (data.data) {
                    fanLocationErrorInfos.value = data.data.split('/n');
                }
            }
        } catch (error) {
            fanPointLoading.value = false;
            fanPointFileList.value = [];
            fanPointFileData.value = [];
            fanLocationRef.value.deleteItem();
        }
        emits('checkFormValidateFields');
        // (通知校验表单风机)
    };
    const getFanDisabled = (value) => {
        console.log(value);
        // 场区组件删除文件，需要关闭风机上传按钮功能
        clearFanDataEvent();
        fanDisabled.value = value;
    }
    // 下载模板文件
    const downLoadFile = (path) => {
        window.open(path);
    };
    // 风机数据清空
    const clearFanDataEvent = () => {
        fanFile.value = [];
        fanPointFileList.value = [];
        fanPointFileInfo.value = [];
        fanPointFileData.value = [];
        fanLocationErrorInfos.value = [];
        fieldFanDataStore.fanFile = [];
        try {
            fanLocationRef.value.deleteItem(0); 
        } catch (error) {
        }
        eventBus.emit('uploadFan', []);
        emits('clearValidateFan');
    }
    watch(
        () => fanFile.value.length,
        () => {
            if (!fanFile.value.length) {
                // 用户删除风机文件，对应的风机坐标数据也要删除
                fanPointFileData.value = [];
                fieldFanDataStore.fanFile = [];
                // fanDisabled.value = false;
                eventBus.emit('uploadFan', []);
            }
        },
    );
    watch(() => fieldFanDataStore.pointData, () => {
        fanDisabled.value = !fieldFanDataStore.pointData.length;
    }, {
        deep: true,
        immediate: true
    })
    onBeforeMount(() => {
        // 场区为文件传入，并且有拐点数据，开放上传按钮
        eventBus.on('fanDisabled', getFanDisabled);
        // 监听场区文件删除，需要清除风机数据
        eventBus.on('clearFanData', clearFanDataEvent)
    })
    onBeforeUnmount(() => {
        eventBus.off('fanDisabled', getFanDisabled);
    })
    defineExpose({
        fanPointFileData,
        fanPointFileInfo,
        fanFile,
        fanLocationRef
    })
</script>
<style scoped lang="scss">
@import '../fieldPoint/fieldPoint.scss';
</style>
