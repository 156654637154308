<template>
    <div class="first-step-component" v-loading="[checkFormLoading, true]">
        <a-form
            ref="formRef"
            name="custom-validation"
            class="frist-step-form"
            :model="formState"
            :rules="rules"
            v-bind="layout"
            :label-col="labelCol"
        >
            <a-form-item label="项目名称" name="projectName">
                <a-input
                    v-model:value="formState.projectName"
                    placeholder="请输入您的项目名称"
                    autocomplete="off"
                    :maxlength="30"
                    show-count
                />
            </a-form-item>
            <a-form-item label="项目总规划装机容量" name="totalProjectCapacity">
                <a-input
                    autocomplete="off"
                    placeholder="请输入总装机容量"
                    suffix="MW"
                    v-model:value="formState.totalProjectCapacity"
                />
            </a-form-item>
            <a-form-item label="本期规划装机容量" name="currentProjectCapacity">
                <a-input
                    autocomplete="off"
                    placeholder="请输入本期装机容量"
                    suffix="MW"
                    v-model:value="formState.currentProjectCapacity"
                    @blur="sessionSaveCapacity"
                />
            </a-form-item>
            <a-form-item label="储能系统" name="energyStorage">
                <a-radio-group v-model:value="formState.energyStorage" :options="storageOptions" />
            </a-form-item>
            <div class="left-indent" style="margin-bottom: 0" v-if="formState.energyStorage === 2">
                <a-form-item class="auto-form-item" label="租借单价" name="rentalUnitPrice">
                    <a-row type="flex" align="middle">
                        <a-input-number
                            v-model:value="formState.rentalUnitPrice"
                            :controls="false"
                        ></a-input-number>
                        <span style="margin-left: 12px">万元 MWH/年</span>
                    </a-row>
                </a-form-item>
            </div>
            <div
                class="left-indent"
                style="margin-bottom: 10px"
                v-if="formState.energyStorage === 2"
            >
                <a-form-item class="auto-form-item" label="费用支付方式" name="rentalPayType">
                    <a-select
                        class="special-select"
                        v-model:value="formState.rentalPayType"
                        placeholder=""
                    >
                        <a-select-option v-for="item in payTypeOptions" :value="item['value']">{{
                            item['label']
                        }}</a-select-option>
                    </a-select>
                </a-form-item>
            </div>
            <div
                class="left-indent"
                style="margin-bottom: -20px"
                v-if="formState.energyStorage == 1 || formState.energyStorage == 2"
            >
                <a-form-item class="auto-form-item" label="" name="">
                    <div style="display: flex">
                        <a-form-item
                            style="width: 240px"
                            label="配置容量占本期比例&nbsp&nbsp&nbsp&nbsp"
                            name="capacityProportion"
                            :label-col="{
                                style: {
                                    width: '300px',
                                },
                            }"
                        >
                            <a-input
                                style="width: 80px"
                                autocomplete="off"
                                placeholder=""
                                suffix="%"
                                v-model:value="formState.capacityProportion"
                                @blur="sessionSaveCapacity"
                            />
                        </a-form-item>
                        <a-form-item name="storageHours">
                            <a-input
                                style="width: 120px"
                                autocomplete="off"
                                placeholder=""
                                suffix="个小时储电"
                                v-model:value="formState.storageHours"
                                @blur="sessionSaveCapacity"
                            />
                        </a-form-item>
                    </div>
                </a-form-item>
            </div>
            <a-form-item label="地区地形" name="energyStorage">
                <a-radio-group
                    v-model:value="formState.terrainType"
                    :options="terrainTypeOptions"
                />
            </a-form-item>
            <a-form-item label="场区坐标" name="pointType">
                <a-radio-group v-model:value="formState.pointType" :options="pointOptions" />
            </a-form-item>
            <fieldPoint
                ref="fieldPointRef"
                :pointType="formState.pointType"
                @checkFormValidateFields="checkFormValidateFields"
            ></fieldPoint>
            <a-form-item label="企业简介" name="companyProfile">
                <a-textarea
                    disabled
                    v-model:value="formState.companyProfile"
                    :rows="4"
                    placeholder="企业简介"
                />
                <div class="modal-content-desc">如需更改企业介绍，可联系平台客服</div>
            </a-form-item>
            <div class="handle-submit-group">
                <a-button
                    v-softDog="{
                        callback: onSubmitForm,
                        params: 1,
                        packageType: PACKAGE_TYPE.proposal,
                    }"
                    v-if="route.query.type !== 'edit'"
                    >保存草稿</a-button
                >
                <a-button
                    v-softDog="{
                        callback: onSubmitForm,
                        params: 2,
                        packageType: PACKAGE_TYPE.proposal,
                    }"
                    class="step-next"
                    >下一步</a-button
                >
            </div>
        </a-form>
    </div>
</template>
<script setup>
    import dayjs from 'dayjs';
    import { useDebounceFn } from '@vueuse/core';
    import { reactive, ref, onMounted, onBeforeMount, h, nextTick } from 'vue';
    import { routerKey, useRoute, useRouter } from 'vue-router';
    import { message, Modal } from 'ant-design-vue';
    import eventBus from '@/utils/eventBus';
    import {
        uniqueIdentification, // 生成唯一标识
        saveFormData, // 保存更新提交表单（新增）
        updateFormData, // 调整保存或者更新表单（调整）
        selectProjectInfoById, //根据项目ID进行信息回显
    } from '@/api/modules/wind/proposalStep';
    import { PACKAGE_TYPE } from '@/assets/js/static.js';
    import { coverPoints, getDictionary,getCustomer} from '@/api/modules/base.js';
    import fieldPoint from '../../../components/fieldPoint/fieldPoint.vue';
    import usefieldFanDataStore from '@/pinia/modules/fieldFanData.js';
    import { useObsUpload } from '@/components/obsClient/obsUpload.js';
    const fieldFanDataStore = usefieldFanDataStore();
    const fieldPointRef = ref(null);
    const route = useRoute();
    const router = useRouter();
    // 表单REF
    const formRef = ref(); // 整体表单
    const handlePointRef = ref(null); // 手动填写场区坐标点组件
    const labelCol = { style: { width: '270px', marginRight: '16px' } };
    // 支付方式
    const payTypeOptions = ref([]);
    // 表单数据变量
    let formState = ref({
        step: 1, // step第一步
        projectName: '',
        totalProjectCapacity: '', // 总装机容量
        currentProjectCapacity: '', // 本期装机容量
        energyStorage: 3, // 储能系统
        storageRatio: null, // 配置容量占本期比例
        storageHours: '', // 配置容量储电时长
        terrainType: 1, // 场区地形
        rentalUnitPrice: '', // 配置容量租借单价
        rentalPayType: null, // 费用支付方式
        pointType: 0, // 场区坐标类型 0批量导入 1手动录入
        companyProfile: JSON.parse(sessionStorage.getItem('customerInfo'))['companyProfile'] || '', //企业简介
    });
    // 验证表单的loading
    const checkFormLoading = ref(false);
    // obs文件存储路径变量
    const obsFilePath = ref('');
    // 生成项目专属随机码
    const getProjectRandom = () => {
        return dayjs(new Date()).format('YYYYMMDDHHmmss').toString() + createRandom(6);
    };
    const createRandom = (n) => {
        return parseInt(Math.random() * Math.pow(10, n));
    };
    // 用户输入本期容量先临时存储
    // const sessionSaveCapacity = async () => {
    //     sessionStorage.setItem('currentProjectCapacity', formState.value.currentProjectCapacity);
    //     console.log(fieldFanDataStore.pointData);
    //     // 手动录入坐标情况下，生成圆的半径与本期规划容量大小有关联
    //     if (fieldFanDataStore.pointData.length && formState.value.pointType == 1) {
    //         console.log(fieldFanDataStore.pointData);
    //         const originData = JSON.parse(JSON.stringify(fieldFanDataStore.pointData));
    //         let formatData = [];
    //         const resultData = [];
    //         fieldFanDataStore.pointData = [];
    //         const res = await formatLatLng(originData, 'lng', 'lat');
    //         formatData = res;
    //         formatData.length &&
    //             formatData.map((ele) => {
    //                 resultData.push([ele.lat * 1, ele.lng * 1]);
    //                 fieldFanDataStore.pointData.push({
    //                     regionCode: 1,
    //                     longitude: ele.lng,
    //                     latitude: ele.lat,
    //                 });
    //             });
    //         console.log(resultData, 'resultData');
    //         eventBus.emit('uploadPoint', [resultData]);
    //         eventBus.emit('fanDisabled', false);
    //     }
    // };
    const sessionSaveCapacity = async () => {
        if (!formState.value.currentProjectCapacity) {
            return false;
        }
        sessionStorage.setItem('currentProjectCapacity', formState.value.currentProjectCapacity);
        console.log(fieldFanDataStore.pointData);
        // 手动录入一个坐标情况下，生成圆的半径与本期规划容量大小有关联
        if (fieldFanDataStore.pointData.length == 1 && formState.value.pointType == 1) {
            console.log(fieldFanDataStore.pointData);
            const originData = JSON.parse(JSON.stringify(fieldFanDataStore.pointData));
            originData.length && originData.map(ele => {
                ele.lng = ele.longitude * 1;
                ele.lat = ele.latitude * 1;
            });
            let formatData = [];
            const resultData = [];
            fieldFanDataStore.pointData = [];
            const res = await formatLatLng(originData, 'lng', 'lat');
            formatData = res;
            formatData.length &&
                formatData.map((ele) => {
                    resultData.push([ele.lat * 1, ele.lng * 1]);
                    fieldFanDataStore.pointData.push({
                        regionCode: 1,
                        longitude: ele.lng,
                        latitude: ele.lat,
                    });
                });
            console.log(resultData, 'resultData');
            eventBus.emit('uploadPoint', [resultData]);
            eventBus.emit('fanDisabled', false);
        }
    };
    // 客服信息
    const customInfos = ref();
    onBeforeMount(() => {
        updateCompanyName()
    })
    onMounted(() => {
        getPayDictionary();
        if (sessionStorage.getItem('customerInfo')) {
            customInfos.value = JSON.parse(sessionStorage.getItem('customerInfo'));
        }
        if (!!route.query.type) {
            // 项目回显
            getProjectInfoByID(sessionStorage.getItem('projectID'));
        } else {
            // 新增项目创建一个路径地址
            sessionStorage.removeItem('projectID');
            obsFilePath.value =
                sessionStorage.getItem('userName') +
                '/jys/pro-' +
                createRandom(6) +
                '/' +
                getProjectRandom() +
                '/od/';
            sessionStorage.setItem('obsFilePath', obsFilePath.value);
            // 新增情况下，直接生成一个新的唯一标识
            fieldFanDataStore.uniqueIdentificationUUID = uniqueIdentification();
        }
    });
    // 验证反馈
    let validateName = async (_rult, value) => {
        if (!value) {
            return Promise.reject('请输入您的项目名称');
        } else {
            if (value.length < 2) {
                return Promise.reject('项目名称最少两个字符');
            } else {
                return Promise.resolve();
            }
        }
    };
    let validateTotalCapacity = async (_rult, value) => {
        const formateValue = Number(value);
        if (!value || isNaN(formateValue)) {
            formState.value.totalProjectCapacity = '';
            return Promise.reject('请输入总装机容量');
        } else {
            if (formateValue <= 0 || formateValue > 1000) {
                return Promise.reject('总装机容量范围为0~1000,可输入2位小数点');
            } else {
                if (
                    formState.value.currentProjectCapacity &&
                    (formState.value.currentProjectCapacity < formateValue ||
                        formState.value.currentProjectCapacity > formateValue)
                ) {
                    formRef.value.validateFields(['currentProjectCapacity']);
                }
                formState.value.totalProjectCapacity = formateValue.toFixed(2);
                return Promise.resolve();
            }
        }
    };
    let validateCurrentCapacity = async (_rult, value) => {
        const formateValue = Number(value);
        if (!value || isNaN(formateValue)) {
            formState.value.currentProjectCapacity = '';
            return Promise.reject('请输入本期装机容量');
        } else {
            if (formateValue <= 0 || formateValue > 1000) {
                return Promise.reject('本期装机容量范围为0~1000,若您有需要可联系平台客服处理');
            } else {
                if (formState.value.totalProjectCapacity < formateValue) {
                    return Promise.reject('本期装机容量不能大于总装机容量');
                }
                formState.value.currentProjectCapacity = formateValue.toFixed(2);
                return Promise.resolve();
            }
        }
    };
    // let validateStorageRatio = async (_rule, value) => {
    //     if (formState.value.energyStorage !== 3 && formState.value.select == null) {
    //         return Promise.reject('请选择配置容量占本期比例');
    //     } else {
    //         return Promise.resolve();
    //     }
    // };
    let validateStorageRatio = async (_rult, value) => {
        const formateValue = parseInt(value);
        if (!value || isNaN(formateValue)) {
            formState.value.storageRatio = '';
            return Promise.reject('请输入配置比例');
        } else {
            formState.value.capacityProportion = formateValue;
            if (formState.value.energyStorage !== 3) {
                if (formateValue <= 0 || formateValue >= 100) {
                    return Promise.reject('配置比例范围为0~100之间');
                } else {
                    return Promise.resolve();
                }
            } else {
                return Promise.resolve();
            }
        }
    };
    let validateStorageHours = async (_rult, value) => {
        const formateValue = parseInt(value);
        if (!value || isNaN(formateValue)) {
            formState.value.storageRatio = '';
            return Promise.reject('请输入小时数');
        } else {
            console.log(formState.value.energyStorage);
            formState.value.storageHours = formateValue;
            if (formState.value.energyStorage !== 3) {
                if (formateValue <= 0 || formateValue > 24) {
                    return Promise.reject('配置小时数为0~24的正整数');
                } else {
                    return Promise.resolve();
                }
            } else {
                return Promise.resolve();
            }
        }
    };
    let validateStoragePrice = async (_rule, value) => {
        if (formState.value.energyStorage == 2 && !formState.value.rentalUnitPrice) {
            return Promise.reject('请输入租借单价');
        } else {
            return Promise.resolve();
        }
    };
    // 场区坐标文件验证
    let validatePointType = async (_rule, value) => {
        if (formState.value.pointType == 0) {
            if (fieldFanDataStore.pointFile && fieldFanDataStore.pointFile.length > 0) {
                if (
                    fieldPointRef.value.pointFileInfo.length &&
                    fieldPointRef.value.pointFileInfo[0]['filePath']
                ) {
                    return Promise.resolve();
                } else {
                    return Promise.reject('场区坐标文件上传异常，请删除后重新上传！');
                }
            } else {
                return Promise.reject('请上传场区坐标文件！');
            }
        } else {
            if (
                fieldPointRef.value.handlePointRef.getData() &&
                fieldPointRef.value.handlePointRef.getData().length > 0
            ) {
                return Promise.resolve();
            } else {
                // return Promise.reject('请录入场区拐点坐标！');
                return Promise.reject('');
            }
        }
    };
    const rules = reactive({
        projectName: [
            {
                required: true,
                validator: validateName,
                trigger: 'blur',
            },
        ],
        totalProjectCapacity: [
            {
                required: true,
                validator: validateTotalCapacity,
                trigger: 'blur',
            },
        ],
        // 本期规划装机容量
        currentProjectCapacity: [
            {
                required: true,
                validator: validateCurrentCapacity,
                trigger: 'blur',
            },
        ],
        // 储能
        energyStorage: [
            {
                required: true,
            },
        ],
        // 场区坐标导入类型
        pointType: [
            {
                required: true,
                validator: validatePointType,
                trigger: 'change',
            },
        ],
        // 配置容量占本期比例
        capacityProportion: [
            {
                required: true,
                validator: validateStorageRatio,
                trigger: 'blur',
            },
        ],
        // 配置容量占本期比例
        storageHours: [
            {
                required: true,
                validator: validateStorageHours,
                trigger: 'blur',
            },
        ],
        // 储能系统租借单价
        rentalUnitPrice: [
            {
                required: true,
                validator: validateStoragePrice,
                trigger: 'blur',
            },
        ],
        // 支付方式
        rentalPayType: [
            {
                required: true,
            },
        ],
    });
    // 是否显示高级参数
    let showMoreKey = ref(false);
    // 储能系统常量
    const storageOptions = [
        {
            label: '自建',
            value: 1,
        },
        {
            label: '租借',
            value: 2,
        },
        {
            label: '不配置',
            value: 3,
        },
    ];
    // 地区地形
    const terrainTypeOptions = [
        {
            label: '山地',
            value: 1,
        },
        {
            label: '平原',
            value: 3,
        },
        {
            label: '丘陵',
            value: 2,
        },
    ];
    // 场区坐标常量
    const pointOptions = [
        {
            label: '批量导入',
            value: 0,
        },
        {
            label: '手动录入',
            value: 1,
        },
    ];
    // 场区坐标手动录入结果验证，用于控制风机点位上传disabled
    const handleDisabled = ref(false);
    // 场区对应的腾讯地图数据变量
    const tdMapPointData = ref([]);
    // 表单样式变量
    const layout = {
        labelCol: {
            span: 4,
        },
        wrapperCol: {
            span: 14,
        },
    };
    const getPayDictionary = async () => {
        try {
            let params = {
                dictType: 'rental_pay_type',
            };
            let res = await getDictionary(params);
            if (res.code === 200 && res.data && res.data.length) {
                payTypeOptions.value = res.data;
            }
        } catch (error) {}
    };
    // 自定义验证逻辑
    const customValidate = () => {
        console.log('没进来？');
        if (formState.value.pointType == 1) {
            if (
                !fieldPointRef.value.handlePointRef.getData() ||
                fieldPointRef.value.handlePointRef.getData().length == 0
            ) {
                message.error('请先完整录入场区坐标');
                formRef.value.validateFields(['pointType']);
                return false;
            }
        } else {
            console.log(fieldFanDataStore.pointData);
            // if (fieldFanDataStore.pointFile.length == 0 || pointFileList.value.length == 0) {
            // if (!fieldFanDataStore.pointFile || fieldFanDataStore.pointFile.length == 0) {
            if (fieldFanDataStore.pointData.length == 0) {
                // message.error('请先上传场区坐标文件');
                formRef.value.validateFields(['pointType']);
                return false;
            }
        }
        // 仅针对项目建议书，需要考虑场区面积和本期规划容量之间的关系 用户上传的场区面积km²‌ >= （本期规划容量/6.25）*0.24，可正常保存，若< ,则弹窗提示"场区面积过小，无法支持满足本期规划装机容量，若有问题可联系平台客服"
        if (sessionStorage.getItem('areaPolygon')) {
            if (
                sessionStorage.getItem('areaPolygon') <
                (formState.value.currentProjectCapacity / 6.25) * 0.24
            ) {
                Modal.warning({
                    title: '温馨提示',
                    content: h('div', { class: 'modal-content-div' }, [
                        h(
                            'p',
                            `场区面积过小，无法支持满足本期规划装机容量，若有问题可联系平台客服`,
                        ),
                        h('p', { class: 'modal-content-desc' }, `平台客服：155-6259-8617`),
                    ]),
                    okText: '知道了',
                    onOk: () => {},
                });
                return false;
            }
        }
        return true;
    };
    // 手动提交表单
    const onSubmitForm = useDebounceFn((type) => {
        formRef.value
            .validate()
            .then(() => {
                // 如果没有通过组件自定义验证，直接拦截掉
                if (!customValidate()) {
                    return false;
                }
                // 表单验证通过,对入参数据进行处理
                formateSubmitFormData(type);
            })
            .catch((err) => {
                // 表单验证没通过
                console.log(err);
                // formRef.value.scrollToField(err.errorFields[0].name);
                console.log('错误', err);
                setTimeout(() => {
                    const errorDiv = document.getElementsByClassName('ant-form-item-explain-error');
                    errorDiv.length &&
                        errorDiv[0].scrollIntoView({ behavior: 'smooth', block: 'center' });
                }, 50);
            });
    }, 500);
    // 经纬度格式化处理，大地坐标转十进制经纬度
    const formatLatLng = async (data, lngStr, latStr) => {
        const formatData = [];
        const result = [];
        data.length &&
            data.map((ele) => {
                formatData.push({
                    longitude: ele[lngStr] + '',
                    latitude: ele[latStr] + '',
                });
            });
        console.log({ points: formatData });

        try {
            const res = await coverPoints({ points: formatData });
            console.log(res);
            if (res.code == 200) {
                if (res.data && JSON.parse(res.data).length) {
                    JSON.parse(res.data).map((item) => {
                        const objItem = {};
                        objItem[lngStr] = item.longitude;
                        objItem[latStr] = item.latitude;
                        result.push(objItem);
                    });
                    console.log(result, '转完的结果');
                    return result;
                }
            } else {
                return [];
            }
        } catch (error) {
            console.log(error);
            return [];
        }
    };
    // 提交时，表单数据格式整理
    const formateSubmitFormData = async (type) => {
        console.log('---------------------');
        // 项目名称格式处理，结尾没有工程、项目两个字要拼接“工程”
        const checkProjectName = (name) => {
            if (name.indexOf('项目') > -1 || name.indexOf('工程') > -1) {
                return name;
            } else {
                return name + '工程';
            }
        };
        // 基础没有高级参数设置情况下
        const params = {
            proposalStep: 1,
            projectAdjustOneStepFlag: true, // 是否是第一步
            projectAdjustFlag: !!(route.query.type == 'edit'), // 项目调整标识
            uniqueIdentification: fieldFanDataStore.uniqueIdentificationUUID, // 用于绑定场区和风机文件关联性的唯一标识
            projectName: checkProjectName(formState.value.projectName), // 项目名称
            totalProjectCapacity: formState.value.totalProjectCapacity, // 总规划装机容量
            currentProjectCapacity: formState.value.currentProjectCapacity, // 本期装机容量
            energyStorage: formState.value.energyStorage, // 储能系统
            rentalUnitPrice:
                formState.value.energyStorage == 2 ? formState.value.rentalUnitPrice : 0, // 储能租借单价
            capacityProportion:
                formState.value.energyStorage !== 3 ? formState.value.capacityProportion : 0, // 储能配置比例
            storageHours: formState.value.energyStorage !== 3 ? formState.value.storageHours : 0, // 储能配置电量时长
            preferDeviceCodes: '',
            obsPath: obsFilePath.value,
            terrainType: formState.value.terrainType, // 地形类型
        };
        // 场区坐标数据格式处理
        if (formState.value.pointType == 0) {
            // 批量导入情况
            params.coordinate = JSON.stringify(fieldPointRef.value.pointFileData);
            params.regionFilePath = JSON.stringify(fieldPointRef.value.pointFileInfo);
        } else {
            // 手动录入情况
            let pointData = [];
            const res = await formatLatLng(
                fieldPointRef.value.handlePointRef.getData(),
                'lng',
                'lat',
            );
            pointData = res;
            const resultPointData = [];
            pointData &&
                pointData.map((ele) => {
                    resultPointData.push({
                        regionCode: 1,
                        longitude: ele.lng,
                        latitude: ele.lat,
                    });
                });
            params.coordinate = JSON.stringify(resultPointData);
            params.regionFilePath = JSON.stringify([]);
        }
        // 如果是项目调整(或者草稿编辑)的话，需要传项目ID
        params['adjustProposalId'] = sessionStorage.getItem('projectID');
        params['proposalId'] = sessionStorage.getItem('projectID');
        if (route.query.type) {
            // 草稿、编辑 + 用于解决用户在第一步点击保存草稿又点击下一步
            params['projectAdjustId'] = sessionStorage.getItem('projectID');
            params['projectId'] = sessionStorage.getItem('projectID');
            params['id'] = sessionStorage.getItem('projectID');
            params['proposalId'] = sessionStorage.getItem('projectID');
            // 把旧的项目ID存到临时缓存里面
            sessionStorage.setItem('originProjectID', sessionStorage.getItem('projectID'));
            if (route.query.type == 'edit') {
                params['isDraft'] = false;
            } else {
                params['isDraft'] = true;
            }
        } else {
            if (type == 1) {
                // 新增草稿
                params['isDraft'] = true;
            } else {
                params['isDraft'] = false;
            }
        }
        if (formState.value.energyStorage == 2) {
            params['rentalPayType'] = Number(formState.value.rentalPayType); // 支付方式
        }
        // 将整理好的入参，通过接口传给后台
        console.log(params);
        // debugger;
        checkFormLoading.value = true;
        try {
            // const data =
            //     route.query.type == 'edit'
            //         ? await updateFormData(params)
            //         : await saveFormData(params);
            const data = await saveFormData(params);
            if (data && data.code == 200) {
                checkFormLoading.value = false;
                message.success('保存成功');
                // 临时存储唯一标识和项目id
                sessionStorage.setItem(
                    'uniqueIdentification',
                    fieldFanDataStore.uniqueIdentificationUUID,
                );
                sessionStorage.setItem('projectID', data.data);
                sessionStorage.setItem(
                    'currentProjectCapacity',
                    formState.value.currentProjectCapacity,
                );
                // 接口保存成功
                if (type == 1) {
                    // 保存成草稿，停留在当前页面(添加草稿标识参数)
                    gtag('event', 'save_draft', {
                        user_info_id: sessionStorage.getItem('userId'),
                        project_info_id: sessionStorage.getItem('projectID'),
                    });
                } else {
                    // 点击下一步才会往下走
                    eventBus.emit('validateStep', 2);
                }
            } else {
                if (data && data.code == 400) {
                    Modal.warning({
                        title: '温馨提示',
                        content: h('div', { class: 'modal-content-div' }, [
                            h('p', `${data.msg}`),
                            h(
                                'p',
                                { class: 'modal-content-desc' },
                                `您的客户经理：${customInfos.value?.name} ${customInfos.value?.phone}`,
                            ),
                            h('p', { class: 'modal-content-desc' }, `平台客服：155-6259-8617`),
                        ]),
                        okText: '知道了',
                        onOk: () => {},
                    });
                }
                checkFormLoading.value = false;
            }
        } catch (error) {
            checkFormLoading.value = false;
        }
    };
    // 手动触发表单场区验证\风机验证
    const checkFormValidateFields = () => {
        console.log('表单场区验证');
        formRef.value.validateFields(['pointType']);
    };
    // 根据项目ID进行回显
    const getProjectInfoByID = async (projectID) => {
        showMoreKey.value = true;
        const data = await selectProjectInfoById({ id: projectID });
        if (data && data.code == 200) {
            const infos = data.data;
            sessionStorage.setItem('currentProjectCapacity', infos.currentProjectCapacity);
            formState.value = Object.assign(
                formState.value,
                {
                    projectName: infos.projectName,
                    totalProjectCapacity: infos.totalProjectCapacity, // 总装机容量
                    currentProjectCapacity: infos.currentProjectCapacity, // 本期装机容量
                    energyStorage: infos.energyStorage,
                    rentalUnitPrice: infos.rentalUnitPrice ? infos.rentalUnitPrice : '', // 配置容量����单价
                    capacityProportion: infos.capacityProportion,
                    storageHours: infos.storageHours,
                    terrainType: infos.terrainType,
                    rentalPayType: infos.rentalPayType ? `${infos.rentalPayType}` : null, // 支付方式
                },
                {},
            );

            // obs文件上传路径处理（调整步骤调整第四级路径）
            if (infos.obsPath) {
                const pathArr = infos.obsPath.split('/');
                pathArr[3] = getProjectRandom();
                let str = '';
                pathArr.map((ele, eleIndex) => {
                    str += ele;
                    if (eleIndex != pathArr.length - 1) {
                        str += '/';
                    }
                });
                obsFilePath.value = str;
                sessionStorage.setItem('obsFilePath', obsFilePath.value);
            }

            // 场区坐标点存放到pinia
            fieldFanDataStore.pointData = infos.coordinate ? JSON.parse(infos.coordinate) : [];
            // 往场区坐标组件里面塞数据
            if (fieldPointRef.value) {
                fieldPointRef.value.pointFileData = infos.coordinate
                    ? JSON.parse(infos.coordinate)
                    : [];
                fieldPointRef.value.pointFileList = infos.coordinate
                    ? [
                          {
                              filePath: '',
                              suffix: '',
                              fileName: '',
                          },
                      ]
                    : [];
            }

            // 拿到详情传过来的文件数组，对应模块文件数据回填
            nextTick(() => {
                if (infos.projectFileList) {
                    // 场区文件回显
                    const pointFile = infos.projectFileList.filter((ele) => {
                        return ele.fileType == 10;
                    });
                    if (pointFile.length > 0) {
                        pointFile.forEach((element) => {
                            element.filePath = decodeURIComponent(element.filePath);
                            element.suffix = element.fileSuffix
                                ? element.fileSuffix
                                : element.fileName.split('.')[1];
                        });
                        fieldFanDataStore.pointFile = pointFile;
                        nextTick(() => {
                            fieldPointRef.value && (fieldPointRef.value.pointFileInfo = pointFile); // 用于组件文件信息提交
                        });
                    }

                    // 天地图场区数据回显
                    let pointsFormat = [];
                    if (infos.coordinate && pointFile.length > 0) {
                        formState.value.pointType = 0;
                        const pointDataObj = {};
                        const pointFileOriginData = JSON.parse(infos.coordinate);
                        pointFileOriginData.map((ele) => {
                            if (!pointDataObj[ele.regionCode]) {
                                pointDataObj[ele.regionCode] = [];
                            }
                            pointDataObj[ele.regionCode].push([ele.latitude, ele.longitude]);
                        });
                        tdMapPointData.value = Object.values(pointDataObj);
                        // fanPointFileData.value = JSON.parse(infos.devicePoints).length
                        //     ? JSON.parse(infos.devicePoints)
                        //     : [];
                        eventBus.emit('uploadPoint', Object.values(pointDataObj));
                    } else if (infos.coordinate && pointFile.length == 0) {
                        formState.value.pointType = 1;
                        const formateResult = [];
                        JSON.parse(infos.coordinate).map((ele) => {
                            formateResult.push({
                                lng: ele.longitude,
                                lat: ele.latitude,
                            });
                            pointsFormat.push([ele.latitude, ele.longitude]);
                        });
                        const pointDataObj = {};
                        const pointFileOriginData = JSON.parse(infos.coordinate);
                        pointFileOriginData.map((ele) => {
                            if (!pointDataObj[ele.regionCode]) {
                                pointDataObj[ele.regionCode] = [];
                            }
                            pointDataObj[ele.regionCode].push([ele.latitude, ele.longitude]);
                        });
                        tdMapPointData.value = Object.values(pointDataObj);
                        // fanPointFileData.value = JSON.parse(infos.devicePoints).length
                        //     ? JSON.parse(infos.devicePoints)
                        //     : [];
                        eventBus.emit('uploadPoint', Object.values(pointDataObj));
                        nextTick(() => {
                            fieldPointRef.value.handlePointRef.updatePointData(formateResult);
                            // handlePointRef.value.updatePointData(formateResult);
                        });
                    }
                    pointFile.length > 0 &&
                        fieldPointRef.value &&
                        fieldPointRef.value.pointLocationRef.updateFileData(pointFile);
                }
            });
        }
    };
    // 更新企业名称
    const updateCompanyName = (name) => {
        getCustomer().then((res) => {
            if (res && res.code == 200) {
                const customerInfo = {
                    name: res.data.customerManagerName,
                    phone: res.data.customerManagerPhone,
                    companyProfile: res.data.companyProfile
                };
                formState.value.companyProfile = res.data.companyProfile;
                sessionStorage.setItem(
                    'customerInfo',
                    JSON.stringify(customerInfo),
                );
            }
        });
    }
</script>
<style scoped lang="scss">
    @import './FirstStep.scss';
    @import '../../../components/handlePoint/handlePoint.scss';
</style>
<style>
    .modal-content-div p:first-child {
        margin: 8px 0 12px 0;
    }
    .modal-content-desc {
        margin-bottom: 5px;
        font-size: 12px;
        color: #7c8294;
    }
</style>
