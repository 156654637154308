<script setup>
import {message} from 'ant-design-vue';
import zhCN from 'ant-design-vue/es/locale/zh_CN';
  // import copyRight from './components/copyRight/CopyRight.vue';
  import { onBeforeUpdate, onMounted, watch } from 'vue';
  import customerService from './components/customerService/customerService.vue';
  import {checkLogIsOpen,isDisabledDevtool} from './utils/utils.js'
  import dayjs from 'dayjs';
  import 'dayjs/locale/zh-cn';
  import usePublicDataStore from '@/pinia/modules/public';
  import {setThemeVariables,themes} from '@/utils/theme.js';
  dayjs.locale('zh-cn');
  const publicDataStore = usePublicDataStore()
onMounted(()=> {
  isDisabledDevtool()
  // checkLogIsOpen()
  // document.addEventListener('keydown', function(event){
  //   return 123 != event.keyCode || (event.returnValue = false)
  // });
  // document.addEventListener('contextmenu', function(event){
  //   return event.returnValue = false
  // })
})
watch(() => publicDataStore.headActiveKey, () => {
  if (publicDataStore.headActiveKey && publicDataStore.headActiveKey == 'light') {
      setThemeVariables(themes.light)
    // window.document.getElementsByTagName('body')[0].style.setProperty('--theme', '#FF8411');
    // window.document.getElementsByTagName('body')[0].style.setProperty('--themeLight', '#F9F3EA');
    // window.document.getElementsByTagName('body')[0].style.setProperty('--themeGradation', 'linear-gradient( 270deg, #F4C62A 0%, #FF8411 100%)');
  } else {
    setThemeVariables(themes.dark)
    // window.document.getElementsByTagName('body')[0].style.setProperty('--theme', '#036B64');
    // window.document.getElementsByTagName('body')[0].style.setProperty('--themeLight', '#f2f7f7');
    // window.document.getElementsByTagName('body')[0].style.setProperty('--themeGradation', 'linear-gradient( 90deg, #049087 0%, #3FAFA8 100%)');
  }
})
// onBeforeUpdate(()=> {
//   //
//   checkLogIsOpen()
// })
// checkLogIsOpen()
</script>

<template>
  <a-config-provider :locale="zhCN"  :theme="{
      token: {
        colorPrimary: '#036b64',
      }
    }">
      <router-view></router-view>
      <!-- <copyRight></copyRight> -->
      <customerService></customerService>
    </a-config-provider>
</template>

<style scoped>
.logo {
  height: 6em;
  padding: 1.5em;
  will-change: filter;
  transition: filter 300ms;
}
.logo:hover {
  filter: drop-shadow(0 0 2em #646cffaa);
}
.logo.vue:hover {
  filter: drop-shadow(0 0 2em #42b883aa);
}
</style>
