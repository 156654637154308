<template>
    <div
        class="customerService"
        :style="{ top: customEleStyle.top, left: customEleStyle.left }"
        ref="customerServiceRef"
        @mousedown="startDrag"
        @mouseleave="mouseLeave"
    >
        <div class="customer">
            <div class="guide-icon" @mouseenter="checkGuideMouseEnter">
                <a-popover v-model:open="visibleGuide" :placement="isLeft ? 'rightBottom' : 'leftBottom'">
                    <template #content>
                        <div class="content-service content-service-guide" @click.stop="goJump">
                            <img style="width: 140px;" src="@/assets/img/guide-page.jpg" alt="">
                            <p>点击即可在线查看</p>
                        </div>
                    </template>
                    <div class="popover-content">
                        <img class="guide" src="@/assets/img/guide-icon.png" alt="" />
                        <span>指南</span>
                    </div>
                </a-popover>
            </div>
            <div class="customer-icon" @mouseenter="checkMouseEnter">
                <a-popover v-model:open="visible" :placement="isLeft ? 'rightBottom' : 'leftBottom'">
                    <template #content>
                        <div class="content-service">
                            <h5>平台客服电话</h5>
                            <h3>155-6259-8617</h3>
                            <h6>( 工作日 9:00~18:00 )</h6>
                            <!-- <h5 v-if="customerInfo.phone">专属客户经理</h5>
                            <h3 v-if="customerInfo.phone">{{ customerInfo.phone }}</h3> -->
                            <h5>官方企业微信客服支持</h5>
                            <img src="../../assets/img/index/wxcode2.png" alt="" />
                        </div>
                    </template>
                    <div class="popover-content popover-content-customer">
                        <img src="@/assets/img/customer-icon.png" alt="" />
                        <span>客服</span>
                    </div>
                </a-popover>
            </div>
        </div>
    </div>
</template>
<script setup>
    import { ref, onMounted, onUnmounted, onUpdated } from 'vue';
    import { getCustomer } from '@/api/modules/base.js';
    // 元素变量
    const customerServiceRef = ref(null);
    // 客服信息显隐变量
    const showService = ref(true);
    // 指南信息显隐变量
    const showGuide = ref(true);
    // 是否是拖拽状态
    const isDragging = ref(false);
    // 元素是否在左半边屏幕
    const isLeft = ref(false);
    // 父级元素样式变量
    const customEleStyle = ref({
        top: 0,
        left: 0,
    });
    // 客服信息
    const customerInfo = ref({
        name: '',
        phone: '',
    });
    const visible = ref(false);
    const visibleGuide = ref(false);
    // 客服信息元素样式变量
    const serviceEleStyle = ref({
        left: -190,
    });
    // 是否首次显示内容
    const isFirstShow = ref(true);
    onMounted(() => {
        resizeStyle();
        window.addEventListener('resize', () => {
            resizeStyle();
        });
    });
    onUpdated(() => {
        if (sessionStorage.getItem('customerInfo')) {
            const infos = JSON.parse(sessionStorage.getItem('customerInfo'));
            customerInfo.value.phone = infos.phone;
            customerInfo.value.name = infos.name;
        } else {
            if (!sessionStorage.getItem('userId')) {
                customerInfo.value.phone = '';
                customerInfo.value.name = '';
            }
        }
    });

    onUnmounted(() => {
        window.removeEventListener('resize', () => {
            resizeStyle();
        });
    });
    const resizeStyle = () => {
        customEleStyle.value = {
            top: window.innerHeight - 174 + 'px',
            left: window.innerWidth - 74 + 'px',
        };
        if (customEleStyle.value.left < window.innerWidth / 2) {
            serviceEleStyle.value.left = 180 + 'px';
        } else {
            serviceEleStyle.value.left = -190 + 'px';
        }
    };
    const checkMouseEnter = () => {
        showGuide.value = false;
        if (isDragging.value) {
            showService.value = false;
        } else {
            showService.value = true;
        }
    };
    const checkGuideMouseEnter = () => {
        showService.value = false;
        if (isDragging.value) {
            showGuide.value = false;
        } else {
            showGuide.value = true;
        }
    };
    const mouseLeave = () => {
        showGuide.value = false;
        showService.value = false;
    };
    const startDrag = (e) => {
        visible.value = false;
        visibleGuide.value = false;
        // 初始化偏移值和拖拽状态
        let offsetX = 0;
        let offsetY = 0;
        isDragging.value = false;
        // 鼠标按下事件
        document.addEventListener('mousedown', (ev) => {
            // 记录鼠标按下时的位置
            offsetX = ev.clientX - customerServiceRef.value.offsetLeft;
            offsetY = ev.clientY - customerServiceRef.value.offsetTop;
            // 记录拖拽状态
            if (
                ev.clientY > customerServiceRef.value.offsetTop &&
                ev.clientY <
                    customerServiceRef.value.offsetTop + customerServiceRef.value.offsetHeight &&
                ev.clientX > customerServiceRef.value.offsetLeft &&
                ev.clientX <
                    customerServiceRef.value.offsetLeft + customerServiceRef.value.offsetWidth
            ) {
                isDragging.value = true;
            }
        });
        // 鼠标移动事件
        document.addEventListener('mousemove', (ev) => {
            if (isDragging.value) {
                visible.value = false;
                visibleGuide.value = false;
                // 卧槽为啥
                let left = ev.clientX - offsetX;
                if (left < 20) {
                    left = 20;
                } else if (left > window.innerWidth - customerServiceRef.value.clientWidth - 20) {
                    left = window.innerWidth - customerServiceRef.value.clientWidth - 20;
                }
                let top = ev.clientY - offsetY;
                if (top < 0) {
                    top = 0;
                } else if (top > window.innerHeight - customerServiceRef.value.clientHeight - 20) {
                    top = window.innerHeight - customerServiceRef.value.clientHeight - 20;
                }
                customEleStyle.value = {
                    top: top + 'px',
                    left: left + 'px',
                };
                if (left < window.innerWidth / 2) {
                    isLeft.value = true;
                    serviceEleStyle.value.left = 80 + 'px';
                } else {
                    isLeft.value = false;
                    serviceEleStyle.value.left = -190 + 'px';
                }
            }
        });
        // 鼠标抬起事件
        document.addEventListener('mouseup', (ev) => {
            if (isDragging.value) {
                visible.value = false;
                visibleGuide.value = false;
                let left = ev.clientX - offsetX;
                if (left < window.innerWidth / 2) {
                    left = 20;
                } else {
                    left = window.innerWidth - customerServiceRef.value.clientWidth - 20;
                }
                customerServiceRef.value.style.transition = 'left ease-in-out 0.3s';
                customEleStyle.value.left = left + 'px';
                isDragging.value = false;
                setTimeout(() => {
                    customerServiceRef.value.style.transition = 'none';
                }, 300);
            }
        });
    };
    const goJump = () => {
        sessionStorage.removeItem('pdfReportObjectKey');
        window.open(location.origin + '/pdfPreview');
    };
</script>
<style scoped lang="scss">
    $primary-color: #036b64;
    $font-color: #363b4d;
    $font-light-color: #7c8294;
    .customerService {
        position: fixed;
        display: flex;
        align-items: flex-end;
        z-index: 1001;
        user-select: none;
        * {
            margin: 0;
            padding: 0;
        }
        .customer {
            background: #ffffff;
            box-shadow: 0px 2px 25px 0px rgba(27, 51, 112, 0.1);
            border-radius: 30px;
            transition: all linear 0.2s;
            cursor: pointer;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            > div {
                width: 48px;
                height: 61px;
                flex: none;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                transition: all linear 0.2s;
                position: relative;
                border-radius: 0 0 30px 30px;
                &:first-child {
                    border-radius: 30px 30px 0 0;
                    &::after {
                        content: '';
                        height: 0.5px;
                        background: #e8ebf6;
                        position: absolute;
                        bottom: 0;
                        width: 39px;
                        z-index: auto;
                    }
                }
                &:hover {
                    background: #dfe9e9;
                    &:first-child::after {
                        content: none;
                    }
                }
                > span {
                    font-size: 12px;
                    margin-top: 4px;
                }
                > img {
                    flex: none;
                    width: 26px;
                    height: 31px;
                    image-rendering: pixelated;
                    &.guide {
                        width: 21px;
                        height: 24px;
                    }
                }
            }
        }
        &:hover {
            .customer {
                border-color: $primary-color;
            }
        }
        .customer-icon {
            font-size: 24px;
        }
    }
    .popover-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        width: 100%;
        > img {
            flex: none;
            width: 17px;
            height: 19px;
            margin-bottom: 4px;
            -webkit-user-drag: none;
            -moz-user-drag: none;
            -ms-user-drag: none;
            // user-drag: none;
        }
    }
    .popover-content-customer {
        > img {
            width: 25px;
            height: 30px;
        }
    }
    .content-service {
        padding: 8px 8px;
        h3 {
            height: 18px;
            font-size: 16px;
            color: $font-color;
            line-height: 18px;
            font-weight: 500;
            margin-bottom: 6px;
        }
        h5 {
            color: #333333;
            font-weight: 400;
            height: 12px;
            line-height: 12px;
            margin-bottom: 6px;
            font-size: 12px;
        }
        h6 {
            height: 12px;
            line-height: 12px;
            font-weight: 400;
            color: $font-light-color;
            margin-bottom: 20px;
            font-size: 12px;
        }
        img {
            width: 72px;
        }
    }
    .content-service-guide{
        cursor: pointer;
        text-align: center;
        img{
            margin-bottom: 12px;
            border: 2px solid transparent;
        }
        p{
            font-weight: 400;
            color: #7C8294;
            font-size: 12px;
            margin: 0;
        }
        &:hover{
            img{
                border-color: #036b64;
                box-sizing: border-box;
            }
            p{
                color: #036b64;
                font-weight: 500;
            }
        }
    }
</style>
<style>
    .ant-popover .ant-popover-inner {
        background: linear-gradient(180deg, #f7f7fe 0%, #fefeff 100%) !important;
        box-shadow: 0px 2px 25px 0px rgba(27, 51, 112, 0.1);
    }
</style>
