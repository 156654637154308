import disableDevtool from 'disable-devtool';
const checkLogIsOpen = () => {
  if (process.env.NODE_ENV == 'production') {
    (function noDebugger() {
      function testDebugger() {
        var d = new Date();
        debugger;
        sessionStorage.clear()
        if (new Date() - d > 10) {
          window.location.replace("about:blank");
          document.body.innerHTML = '<div style="color:red;font-size:18px;margin:20px">年轻人，不要太好奇</div>';
          window.location.replace("about:blank");
          return true;
        }
        return false;
      }

      function start() {
        while (testDebugger()) {
          testDebugger();
        }
      }

      if (!testDebugger()) {
        window.onblur = function () {
          setTimeout(function () {
            start();
          }, 500);
        };
      } else {
        start();
      }
    })();
  }
}
const isDisabledDevtool = () => {
  console.log('disable devtool',process.env.NODE_ENV);
  
  if (process.env.NODE_ENV == 'production') {    
      disableDevtool({
        // 配置选项
        // md5:'47bce5c74f589f4867dbd57e9ca9f808', // 线上环境跳过校验 路径拼接ddtk=aaa
        clearIntervalWhenDevOpenTrigger: true,
        clearLog:false,
        disableMenu:false,
        rewriteHTML:'<div style="color:red;font-size:18px;margin:20px">年轻人，不要太好奇</div>',
        ondevtoolopen: (type,next) => {
            sessionStorage.clear()
            window.location.replace("about:blank");
            next()
        },
    });
  }
}
export {
  checkLogIsOpen,
  isDisabledDevtool
}
